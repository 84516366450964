import React, { useEffect, useState } from 'react'
import $ from 'jquery' // Import jQuery if not already available
import "./style.scss";
import Newsletter from './newsletter'
import swordLogo from 'global/images/ftc2024/cm-sword-logo.png'


export const RenderButton = (props) => {
  return (
      <button 
        className={'button--show btn--ga'}
        data-text="join the crew"
        data-name='newsletter'
        data-section='newsletter cta'
        data-url=""
        onClick={() => props.callback()}
      >
        Join the Crew    
        <img src={swordLogo} alt=""/>
      </button>
   
  )
}

const NflProgressiveModal = (props) => {
  const [isOpen, setIsOpen] = useState(null)
  const [nflnewsletterIsOpen, setnflnewsletterIsOpen] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [ageGateBirthday, setAgeGateBirthday] = useState('')
  const [modalOpened, setModalOpened] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [thanksStatus, setThanksStatus] = useState(false)
  const [isAnswered, setIsAnswered] = useState(false)

  useEffect(() => {
    $('body').on('click', '#age_confirm_btn', function () {
      console.log('age gate click')
      const month = document.getElementById('age_select_month_of_birth')
      const day = document.getElementById('age_select_day_of_birth')
      const year = document.getElementById('age_select_year_of_birth')

      const birthday = month.value + '/' + day.value + '/' + year.value
      setAgeGateBirthday(birthday)
    })

    // window.addEventListener('scroll', function (e) {
    //   const available = window.outerHeight
    //   const percentage_of_page = 0
    //   const twenty_screen = available * percentage_of_page
    //   const height = window.scrollY

    //   if (height > twenty_screen) {
    //     setShowSideBar(true)
    //   } else {
    //     setShowSideBar(false)
    //   }
    // })

    setModalOpened(
      localStorage.getItem('nflPModal') || sessionStorage.getItem('nflPModal') ? true : false,
    )
    setIsAnswered(
      localStorage.getItem('nflPModalAnswered') || sessionStorage.getItem('nflPModalAnswered') ? true : false,
    )
  }, [])

  // useEffect(() => {
  //   if (!isOpen && showSideBar && !modalOpened) {
  //     // setIsOpen(true)
  //     // setShowButton(false)
  //     // setnflnewsletterIsOpen(true)
  //     // setTimeout(() => {
  //     //   $('#prModal').addClass('prmodal--open')
  //     // } , 500)
  //     // setModalOpened(true)
  //   }else{
  //     setShowButton(true)
  //   }
  // }, [showSideBar])

  useEffect(() => {
    const reviewsBtn = document.getElementById('yotpo_testimonials_btn')
    if (reviewsBtn) reviewsBtn.style.display = nflnewsletterIsOpen ? 'none' : 'block'
  }, [nflnewsletterIsOpen])

  const getParameterByName = (name) => {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
  }

  const isPreventShowExpired = () => {
    const today = new Date()
    const storageDate = new Date(sessionStorage['nflPModal'])
    return (today.getTime() - storageDate.getTime()) / (1000 * 3600 * 24) > 30
  }

  const openModal = () => {
    window.dataLayer = window.dataLayer || []
    const events = {
      event: 'customEvent',
      eventCategory: 'Opt-in',
      eventAction: 'pop-up',
      eventLabel: 'shown',
      eventInteraction: 'false',
    }
    window.dataLayer.push(events)

    localStorage['nflPModal'] = new Date()
    if (!globalThis.location?.pathname.includes('/en-us/newsletter')) {
      setIsOpen(true)
    }
  }

  const executeModal = () => {
    setTimeout(openModal, 7000)
  }

  useEffect(() => {
    const search = getParameterByName('newsletter')

    const visited = localStorage['nflPModal'] || false

    if (search) {
      openModal()
    }

    $('#nflPModal').click(function () {
      $('#nflPModal').toggleClass('active')
    })
  }, [])

  useEffect(() => {
    const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
    window.dataLayer = window.dataLayer || []
    if (isOpen) {      
      if(recaptcha) {
        recaptcha.classList.add('z-top')
      }
      window.dataLayer.push({
        event: 'sign_up_step',
        step: 'form shown',
        sign_up_type: 'newsletter',
        form_location:'fly-out modal',
        form_name: 'Follow the Captain Modal',
      })
    }else{
      if(recaptcha) {
        recaptcha.classList.remove('z-top')
      }
    }
  }, [isOpen])

  const handleClick = (e) => {
    e.stopPropagation()
    if (!isOpen) {
      setIsOpen(true)
      setShowButton(false)
      setnflnewsletterIsOpen(true)
      setTimeout(() => {        
        $('#prModal').addClass('prmodal--open')
      }, 500)
    }
  }

  const handleClose = (e) => {
    if (e) e.stopPropagation()
    setIsOpen(false)
    $('#prModal').removeClass('prmodal--open')
    setTimeout(() => { 
      setModalOpened(false)
      setShowSideBar(false)
      setnflnewsletterIsOpen(false)
      props.callback(props.that)
    }, 650)

    localStorage.setItem('nflPModal', 'true')
  }

  const updateThanks = () => {
    setThanksStatus(true)
      window.dataLayer = window.dataLayer || []
     window.dataLayer.push({
          event: 'sign_up',
          step: 'success',
          sign_up_type: 'newsletter',
          form_name: 'Follow the Captain Modal',
          detail: 'newsletter opt-in',
          //"inline", "modal", "fly-out modal" or "footer"
          form_location:'fly-out modal',
          optin_brands: 'Captain Morgan',
        })
    localStorage.setItem('nflPModalAnswered', 'true')
  }

   useEffect(() => {
    if(props.modalstate === true) {
      setIsOpen(props.modalstate)
      setModalOpened(props.modalstate)
      setShowSideBar(props.modalstate)
      setnflnewsletterIsOpen(props.modalstate)
      setTimeout(() => {        
        $('#prModal').addClass('prmodal--open')
      }, 500)
    } else {
      handleClose()
    }
  }, [props.modalstate])

  return (
    <>
      <div id="nflPModal" className={`nflPModal fixed top-0 left-0 z-50 h-full w-full ${isOpen ? 'nflPModal--open' : ''}`}>
          {nflnewsletterIsOpen && (
            <Newsletter
              birthday={ageGateBirthday}
              entrySource={props.entrySource ?? "US_CPT_FTC_FY25_Thorium" }
              eventKey={props.eventKey ?? "APIEvent-ce7de358-2b92-8e7f-ecb5-2b844b9a90e5" }
              isModal
              isOpen={nflnewsletterIsOpen}
              modalClose={handleClose}
              thanksCallBack={updateThanks}
              thanksstatus={thanksStatus}
              subhead={props.subhead ?? "Join our crew to get notified of legendary celebrations throughout the season!"}
            />
          )}
      </div>
    </>
  )
}

export default NflProgressiveModal
