import "./style.scss";
import React, { useEffect,useState } from "react";
import fetch from "isomorphic-fetch";
import moment from "moment";
import $ from 'jquery'

import btnShuffle from 'global/images/ftc2024/btn__cardgame.png'
import btnPlayAgain from 'global/images/ftc2024/btn__cardgame-again.png'
import btnSubmit from 'global/images/ftc2024/btn__submit.png'
import prizeTicket from 'global/images/ftc2024/prize__icon--ticket.png'
import prizeShirt from 'global/images/ftc2024/prize__icon--shirt.png'
import prizeFb from 'global/images/ftc2024/prize__icon--football.png'
import prizeBox from 'global/images/ftc2024/prize__icon--box.png'
import prizeCoupon from 'global/images/ftc2024/ftc2024_coupon.png'

const CardGame = (props) => {
  const [userWin, setUserWin] = useState(true);
  const [userHasWon, setUserHasWon] = useState(true);
  const [userGuessed, setUserGuessed] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [date, setDate] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  useEffect( () => {
    if (userWin) {
      const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
      if(recaptcha) {
        recaptcha.classList.add('z-top')
      }
    }

  }, [gameStarted, userWin])

  useEffect(() => {
     let params = new URL(window.location.href).searchParams
      let skipPrizeGame = params.get('skipPrizeGame')
      if(skipPrizeGame) {
        setUserWin(true)
         setTimeout( () => {
          $('#winwrap').removeClass('fadeIn')
        }, 250)
        return
      }


    const updateGame = (gamestate) => {
      setGameStarted(gamestate)      
    }

    const updateUserGuessed = () => {
      if(userGuessed === false) {
        setUserGuessed(true)
      }
    }
    const loadGame = () => {      
      $(function () {
        var startGame = false
        var game = {
          wins: 0,
          initiate: function () {
            cards.generateCards();
          },
          start: function () {
            startGame = true
            setUserGuessed(false)
            updateGame(true)
            $('#ball').fadeOut(700)            
            var times = 0;
            setTimeout(() => {
              var timerId = setInterval(function() {
                cards.shuffle();
                times +=1;
                if(times === cards.numsOfShuffle) {
                  clearInterval(timerId);
                }
              }, 1000);
            }, 1000)
          },
          reset: function() {
            $('#board > div:nth-child(1)').removeClass().attr("class", "left");
            $('#board > div:nth-child(2)').removeClass().attr("class", "middle");
            $('#board > div:nth-child(3)').removeClass().attr("class", "right");
          },
          winlose: function(that) {
            var $div = $(that);

            startGame = false            
            updateUserGuessed()
            updateGame(false)
            $('#ball').fadeIn(500);
            
            if ($div.children().length) {
              setUserHasWon(true)
              // board.display("You Won!!!");
              $('#start').addClass('cardgame__button--disabled').prop('disabled', true)
              
              setTimeout( () => {
                $('#cardgameWrap').addClass('fadeOut')
                setTimeout( () => {
                  setUserWin(true)
                  setTimeout( () => {
                    $('#winwrap').removeClass('fadeIn')
                  }, 250)
                }, 300)
              }, 1300)
            } else {
              // $('#game-title').html("K")
              // console.log(btnPlayAgain)
              // $('#startImage').attr('src',btnPlayAgain);
              // board.display("You Looooose!")
            }
          }
        }

        var board = {
          display: function (string) {
            $('#display').text(string);
          }
        }

        var cards = {
          numOfCards: 3,
          arrCards: [],
          numsOfShuffle: 5,
          generateCards: function() {
            for (var i = 0; i<this.numOfCards; i+=1) {
              var classArray = ["left", "middle", "right"];
              var $cardDiv = $('<div class = "'+ classArray[i] + '">');
              //var $cardDiv = $('<div class ="card ' + classArray[i] + '">');
              $('#board').append($cardDiv);
                
              this.arrCards.push({
                $el : $cardDiv,
                currentClass: classArray[i],
                swapClass: function(newClass) {
                  var currentClass = this.currentClass;
                  this.$el.removeClass(currentClass);
                  this.$el.attr('class', newClass);
                  this.currentClass = newClass;
                }
              });

              $cardDiv.on("click", function () {
                if(startGame){
                  game.winlose(this);
                }else{
                  console.log('START THE GAME FIRST')
                }
              })
            }

            this.arrCards[1].ball = true;
            var $ballHolder = $('#board div:nth-child(2)');
            var $ball = $('<div id = "ball">');
            $ballHolder.append($ball)
          },
          shuffle: function () {
            var randomPosition1 = Math.floor(Math.random()*this.arrCards.length);
            var randomPosition2 = Math.floor(Math.random()*this.arrCards.length);

            while (randomPosition1 == randomPosition2) {
             randomPosition2 = Math.floor(Math.random()*this.arrCards.length);
            }

            var store = this.arrCards[randomPosition1].currentClass;

            this.arrCards[randomPosition1].swapClass(this.arrCards[randomPosition2].currentClass);
            this.arrCards[randomPosition2].swapClass(store);
          }
        }
        game.initiate();

        // setTimeout(() => {
        //   game.start();
        // }, 3000)

        $('#start').on("click", function(eventObject) {
          // game.reset();
          game.start();
        })

        $('#reset').on("click", function(eventObject) {
          game.reset();
        })
      })
    }

    document.addEventListener("load", loadGame());
    
    return () => {
      document.removeEventListener("load", loadGame());
    }
  }, [])

  const handleDateChange = (e) => {
    let value = e.target.value;
    let backsapce = date.length > value.length;

    if (!backsapce && (date.length == 1 || date.length == 4)) {
      value += "/";
    }
    setDate(value);
  };

  function checkAge (dob) {
    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);
    if (birthday.isValid()) {
      if (moment().diff(birthday, "years", false) < 21 || moment().diff(birthday, "years", false) > 110) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  // eslint-disable-next-line complexity
  async function formSubmit(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#ftcCardGameSubmit').prop('disabled', true).addClass('disabled')
    $('#errorMessage').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });
    event.preventDefault()

    const team = $("#team").find(":selected").val();
    const fname = $("#CP_FirstName").val();
    const lname = $("#CP_LastName").val();
    const email = $("#CP_EmailId").val();
    const dob =  moment($("#CP_DOB").val()).format("YYYY-MM-DD")
    const city = $("#CP_City").val();
    const zip = $("#CP_ZipCode").val();
    const phone = $("#CP_PhoneNumber").val();
    const state = $("#PR_4_466_1_freetext").find(":selected").val();
    const address1 = $("#CP_Address1").val();
    const address2 = $("#CP_Address2").val();
    const tc = $("#tc").prop("checked");
     const rules = $("#rules").prop("checked");
    const checkboxFields = $("#PR_1_64_1").prop("checked");
    let captcha = "";

    if (fname.trim() === "" 
        || lname.trim() === "" 
        || team.trim() === "" 
        || email.trim() === "" 
        || city.trim() === "" 
        || state.trim() === "" 
        || phone.trim() === "" 
        || zip.trim() === "" 
        || address1.trim() === ""
        || dob.trim() === "" 
        || tc === false || rules === false) {

      if (team.trim() === "") {
        $("#team").addClass("error");
      }

      if (fname.trim() === "") {
        $("#CP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#CP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#CP_EmailId").addClass("error");
      }
      if (city.trim() === "") {
        $("#CP_City").addClass("error");
      }
      if (phone.trim() === "") {
        $("#CP_PhoneNumber").addClass("error");
      }
      if (state.trim() === "") {
        $("#PR_4_466_1_freetext").addClass("error");
      }
      if (zip.trim() === "") {
        $("#CP_ZipCode").addClass("error");
      }
      if (address1.trim() === "") {
        $("#CP_Address1").addClass("error");
      }

      if (dob.trim() === "") {
        $("#CP_DOB").addClass("error");
      }
      formError = true
    }
     //check age
    ageError = checkAge(dob)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('ftcCardGame'));
    
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
            // eslint-disable-next-line radix
            send[key] = parseInt(value);
        } else if (key === "PR_4_92_1" || key === "tc") {
          if (value === "on") {
              send[key] = "1";
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "CP_DOB") {
          send[key] = moment($("#CP_DOB").val()).format("YYYY-MM-DD")
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          send[key] = value;
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const apiUrl = '/api/ftc2024/cardgame'
          const res = await fetch(`${apiUrl}`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          if(res && res.success) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
            });
            window.dataLayer.push({
              "event": "sign_up",
              "step":"success",
              "sign_up_type": "competition",
              "optin_brands": "Captain Morgan",
              "form_name": "Follow The Captain Card Game",
              "form_location": 'modal',
              "detail": checkboxFields? "newsletter opt-in" : "newsletter opt-out"
            })
            setFormSubmitted(true)
          }else{
            const message = res && res.data && res.data.detail ? res.data.detail : 'Something went wrong. Please try again.'
            $('#errorMessage').html('<div>' + message + '</div>')
            $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          const message = 'Something went wrong. Please try again.'
          $('#errorMessage').html('<div>' + message + '</div>')
          if($('#ftcCardGameSubmit')) {
            $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        let errorMsg = "";

        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || city.trim() === ""
          || state.trim() === ""
          || phone.trim() === ""
          || zip.trim() === ""
          || address1.trim() === ""
          || dob.trim() === "") {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 21 and over to enter.</div>";
        }

        if (tc === false) {
          errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
        }
        if (rules === false) {
          errorMsg += "<div>Please accept the official rules.</div>";
        }

        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
        $('#errorMessage').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  return (
    <div className="cardgame" id="cardgame">
     {userWin &&
        <div className="cardgame__winwrap text--white win-wrap winwrap" id="winwrap">
          <div className="winwrap__heading">
            {/*<h2 className="winwrap__title text--center">
              YOU SOLVED IT
            </h2>*/}
            {!formSubmitted &&
              <div className="winwrap__headingcopy text--copy">
                Enter your info below for your chance to win some epic prizes.
              </div>
            }
          </div>
          <div className="winwrap__content wincontent flex">
            <div className="wincontent__form form">
              <div className="form__wrap text--white gameform">
                {formSubmitted &&
                  <h3 className="columns__heading">
                    Thank you! We received your submission.
                  </h3>
                }
                {!formSubmitted &&
                  <form className="flex" id="ftcCardGame">
                    <div className="inputWrapper">
                      <label htmlFor="team">Select your team *</label>
                      <select name="team" id="team" className="claimInput input w-input" defaultValue="" required>
                        <option disabled value="">Select your team *</option>
                        <option value="Arizona Cardinals">Arizona Cardinals</option>
                        <option value="Chicago Blackhawks">Chicago Blackhawks</option>
                        <option value="Chicago Bulls">Chicago Bulls</option>
                        <option value="Dallas Cowboys">Dallas Cowboys</option>
                        <option value="Los Angeles Chargers">Los Angeles Chargers</option>
                        <option value="Los Angeles Rams">Los Angeles Rams</option>
                        <option value="Milwaukee Bucks">Milwaukee Bucks</option>
                        <option value="Minnesota Wild">Minnesota Wild</option>
                        <option value="Pittsburgh Penguins">Pittsburgh Penguins</option>
                        <option value="New York Giants">New York Giants</option>
                        <option value="New York Jets">New York Jets</option>
                        <option value="Captain Morgan">Captain Morgan</option>
                      </select>
                      <p>Select your favorite sports team for a chance to win limited edition prizes, or select Captain Morgan for a chance to win exclusive merch! Click below for full rules and details.</p>
                    </div>

                    <div className="inputWrapper half">
                      <div className="">
                        <label htmlFor="CP_FirstName">First Name *</label>
                        <input id="CP_FirstName" name="CP_FirstName" type="text" className="claimInput" placeholder="First Name" required />
                      </div>

                      <div className="">
                        <label htmlFor="CP_LastName">Last Name *</label>
                        <input id="CP_LastName" name="CP_LastName" type="text" className="claimInput" placeholder="Last Name" required />
                      </div>
                    </div>

                    <div className="inputWrapper half">
                      <div className="">
                        <label htmlFor="CP_EmailId">Email *</label>
                        <input id="CP_EmailId" name="CP_EmailId" type="email" className="claimInput" placeholder="Email" required />
                      </div>

                      <div className="">
                        <label htmlFor="CP_DOB">Date of Birth *</label>
                        <input id="CP_DOB" name="CP_DOB" type="text" className="claimInput" required  value={date}
                          maxLength={10}
                          onChange={handleDateChange}
                          placeholder="mm/dd/yyyy"/>
                      </div>
                    </div>

                     <div className="inputWrapper half">
                      <div className="">
                        <label htmlFor="CP_Address1">ADDRESS *</label>
                        <input id="CP_Address1" name="CP_Address1" className="claimInput" placeholder="Address" type="text" required />
                      </div>

                      <div className="">
                        <label htmlFor="CP_Address2">ADDRESS 2</label>
                        <input id="CP_Address2" name="CP_Address2" className="claimInput" placeholder="Address 2" type="text" />
                      </div>
                    </div>

                    <div className="inputWrapper half">
                      <div className="">
                        <label htmlFor="CP_City">City *</label>
                        <input id="CP_City" name="CP_City" className="claimInput" placeholder="City" type="text" required />
                      </div>
                      
                      <div className="">
                        <label htmlFor="PR_4_466_1_freetext">State *</label>
                        <select name="PR_4_466_1_freetext" id="PR_4_466_1_freetext" className="claimInput input w-input" defaultValue="" required>
                          <option disabled value="">State*</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                    </div>

                    <div className="inputWrapper half">
                      <div className="">
                        <label htmlFor="CP_ZipCode">Zip Code *</label>
                        <input id="CP_ZipCode" name="CP_ZipCode" type="text" maxLength={10} className="claimInput" placeholder="Zip Code" required />
                      </div>
                      <div className="">
                        <label htmlFor="CP_PhoneNumber">Phone Number *</label>
                        <input id="CP_PhoneNumber" name="CP_PhoneNumber" maxLength={10} className="claimInput" placeholder="Phone Number" type="tel" required />
                      </div>
                    </div>

                    <div className="inputWrapper checkbox">
                      <input id="tc" name="tc" type="checkbox" className="claimInput cliamInput--checkbox" required />
                      <label htmlFor="tc" dangerouslySetInnerHTML={{
                        __html: `I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a>. *`
                        }}
                      />
                    </div>

                    <div className="inputWrapper checkbox">
                      <input id="rules" name="rules" type="checkbox" className="claimInput cliamInput--checkbox" required />
                      <label htmlFor="rules" dangerouslySetInnerHTML={{
                        __html: `I have read and agree to the <a href='/captain-morgan-nfl-rules.pdf' target='_blank' style='text-decoration:underline;'">Official Rules</a> of this sweepstakes. *`
                        }}
                      />
                    </div>

                    <div className="inputWrapper checkbox">
                      <input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" className="claimInput cliamInput--checkbox" required />
                      <label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                        __html: `Tick here if you would like us to use your email to keep you informed about products, services and events from Captain Morgan & other Diageo brands including TheBar.com. You can unsubscribe at any time.<br /><br />By signing up you accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a>.`
                        }}
                      />
                    </div>

                    <div className="inputWrapper">
                      <div className="errorMessage" id="errorMessage"></div>
                    </div>
                    <div className="inputWrapper half justify-center">
                      <button aria-label="submit" className="submit btn btn--submit btn--reset" type="submit" id='ftcCardGameSubmit' onClick={(event) => formSubmit(event)}>
                        <img src={btnSubmit} aria-hidden="true" />
                      </button>
                    </div>
                  </form>
                }
              </div>
            </div>
            <div className="wincontent__columns columns">
              <div className="contentblock__columns columns text--white flex">
                <div className="columns__copy columns__title text--copy">
                  <img src={prizeCoupon} aria-label="Save on your first order: Use code FOLLOWTHECAPTAIN at www.thebar.com for $5.99 off your first order of any Captain Morgan product. (Shipping only to select states.)" />
                  <span className="sr-only">Save on your first order: Use code FOLLOWTHECAPTAIN at www.thebar.com for $5.99 off your first order of any Captain Morgan product. (Shipping only to select states.)</span>
                </div>
                <div className="columns__heading">

                  You could also win...
                </div>
                <div className="columns__wrap flex justify-center align-start">
                  <div className="columns__item">
                    <img className="columns__image" src={prizeTicket} aria-label="Tickets" />
                    <div className="columns__heading">
                      Exclusive Tickets
                    </div>
                    <div className="columns__title text--copy">
                      Like...<br />
                      Club Seats at Select Games
                    </div>
                  </div>
                  <div className="columns__item">
                    <img className="columns__image" src={prizeShirt} aria-label="Jersey" />
                    <div className="columns__heading">
                      Team Swag
                    </div>
                    <div className="columns__title text--copy">
                      Like...<br />
                      Autographed Helmets<br />
                      Autographed Footballs<br />
                      Framed Photos
                    </div>
                  </div>
                  {/*<div className="columns__item">
                    <img className="columns__image" src={prizeBox} aria-label="Toolbox" />
                    <div className="columns__heading">
                      LOREM IPSUM
                    </div>
                    <div className="columns__title">
                      Lorem ipsum dolor sit amet consectetur adipscing elit, ses.
                    </div>
                  </div>*/}
                  <div className="columns__item">
                    <img className="columns__image" src={prizeFb} aria-label="Football" />
                    <div className="columns__heading">
                      Unique Experiences
                    </div>
                    <div className="columns__title text--copy">
                      Like...<br />
                      Pre-Game Warm Ups<br />
                      Sideline Passes<br />
                      Tunnel Experiences<br />
                      Travel to Team Away Games
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {!userWin &&
        <div className="cardgame__wrap" id="cardgameWrap">  
          {/* <div className="cardgame__heading text--white">CAPTAIN'S CARD SHUFFLE</div>      */}
          <div className={`${gameStarted ? 'isPlaying' : ''}`}id="board" />
          <div className="cardgame__content">
            <div className="cardgame__copy">
              <div className="cardgame__title text--white " id="game-title">
                 {(!userGuessed) &&
                    (<>CAPTAIN'S CARD SHUFFLE</>)
                }
                {(userGuessed && !userHasWon) &&
                    (<>Keep trying for your <br/>chance to win</>)
                }
                 {(userGuessed && userHasWon) &&
                   (<>CAPTAIN'S CARD SHUFFLE</>)
                }
              </div>
              <div className="cardgame__text text--white text--copy">
                {(!userGuessed) &&
                    (<>Keep your eyes on the Captain's card to unlock prizes for you and your crew.</>)
                }
                {(userGuessed && !userHasWon) &&
                    (<>Keep your eyes on the Captain's card to unlock prizes for you and your crew.</>)
                }
                {(userGuessed && userHasWon) &&
                    (<>You solved it!</>)
                }
              </div>
              <button
                aria-label="Shuffle Cards"
                className="cardgame__button btn--reset btn--ga"
                data-text="shuffle cards"
                data-name='shuffle cards'
                data-section='card game'
                data-url=""
                id="start"
              >
                 {(!userGuessed) &&
                   <img src={btnShuffle} aria-hidden="true" id='startImage' />
                }
                 {(userGuessed && !userHasWon) &&
                    <img src={btnPlayAgain} aria-hidden="true" id='startImage' />
                }
              
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default CardGame;
