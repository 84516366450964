import "./hero.scss";
import Cta from "../../atoms/cta";
import HeroBackground from "../../molecules/hero-background";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

export default class VideoHero extends React.Component {

    render () {

        const {props} = this;
        const {
            HeroSummary,
            HeroTitle,
            VideoMedia,
            CtaCollection,
            HideTitle,
        } = fieldsArrayToKeyValueHash(props.fields);

        return (
            <header className="hero">
                <HeroBackground video={VideoMedia} />
                <div className="hero__content">
                    <h1 className={`hero__title ${HideTitle ? 'sr-only' : ''}`}>{HeroTitle}</h1>
                    {
                        HeroSummary &&
                        <div
                            className="hero__summary"
                            dangerouslySetInnerHTML={{__html: sanitise(HeroSummary)}}
                        />
                    }
                    {
                        CtaCollection &&
                            CtaCollection.map((cta, index) => <Cta key={`cta-${index}`} cta={cta}/>)
                    }
                </div>
            </header>
        );

    }

}
