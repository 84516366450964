/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'

import * as utils from 'global/utils/utils'

import AppError from "../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import HeadingWithCopy from "../heading-with-copy/";
import Footer from "../footer";
import buildPage from "../../page-builder";

import NavCustom from "../nav-custom";

const cn = require('classnames')

import GameLayer from "../game-layer"

import fotyBanner from 'global/images/ftc2024/NFL_FOY_spon_horz_alt3_RGB_r.png'
import fotyBannerMobile from 'global/images/ftc2024/foty_banner--mobile.png'
import btnReadMore from 'global/images/ftc2024/btn__readmore.png'
import fotyBgWhiteTop from 'global/images/ftc2024/foty/bg__foty-white--top.png'
import fotyBgWhiteBot from 'global/images/ftc2024/foty/bg__foty-white--bot.png'
import fotyTrophy from 'global/images/ftc2024/foty/foty__trophy.png'
import fotyWinner from 'global/images/ftc2024/foty/foty__winner.png'
import sideBtn from 'global/images/ftc2024/side_prizebtn.png'

import image1 from 'global/images/ftc2024/foty/foty__carousel-1.png'
import image2 from 'global/images/ftc2024/foty/foty__carousel-2.png'
import image3 from 'global/images/ftc2024/foty/foty__carousel-3.png'
import image4 from 'global/images/ftc2024/foty/foty__carousel-4.png'
import image5 from 'global/images/ftc2024/foty/foty__carousel-5.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "image": fotyBanner,
      "imageMobile": fotyBannerMobile,
      "imagealt": "NFL Fan of the Year, presented by Captain Morgan",
      "title": "",      
      "srText": "NFL Fan of the Year, presented by Captain Morgan",
      "copy": "",
      "imageSize":"foty",
      "pModalOpen": false,
    },
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler () {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }

  initGallery() {
    const gallerySlider = document.getElementsByClassName('gallery-slider')

    if (gallerySlider.length > 0) {
      var slider = new Swiper ('.gallery-slider', {
        slidesPerView: 1,
        // centeredSlides: true,
        loop: true,
        loopedSlides: 6,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
      });
    }
  }
   clickHandler (clicked, that, game) {

    if(game !== '') {
      that.setState({
        "inGame": true,
        "currentGame": game,
      })
      setTimeout(() => {
        const gameLayer = document.getElementById('gamelayer')
        const nflPModal = document.getElementById('nflPModal')
        that.lockElements()
        if (gameLayer) {
          gameLayer.classList.remove('slideOut')
          gameLayer.classList.add('slideIn')
          nflPModal.classList.add('z-top')
        }
      }, 1000)
    }
  }

  closeOverlayCallback (that) {
    const gameLayer = document.getElementById('gamelayer')
    const nflPModal = document.getElementById('nflPModal')
    const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
    this.unLockElements()
    if (gameLayer) {
      gameLayer.classList.add('slideOut')
      gameLayer.classList.remove('slideIn')
      nflPModal.classList.remove('z-top')
      if(recaptcha) {
        recaptcha.classList.remove('z-top')
      }
    }
    
    setTimeout(() => {
      this.setState({
        "inGame": false,
        "currentGame": "",
      })
    }, 1000)
  }
  lockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.add('locked')
    bodyElem.classList.add('locked')
    navElem.classList.add('locked')
  }

  unLockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.remove('locked')
    bodyElem.classList.remove('locked')
    navElem.classList.remove('locked')
  }


  waitForElem () {
    const gallerySlider = document.getElementsByClassName('gallery-slider')
    if(gallerySlider !== "undefined"){
      setTimeout( () => {
        this.initGallery()
      }, 500)
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }
  
  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text') || ''
        var btnHref = $(this).data('url') || ''
        var btnPath = $(this).data('path') || ''
        var btnName = $(this).data('name') || ''
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnPath,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })
    })
    setTimeout( () => { this.waitForElem(), 1500})
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "NFL Fans of the Year | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'NFL Fans of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          path: '/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    const galleryData = [
      // {
      //   image: image1,
      //   imagealt: "FOTY image 1",
      //   name: "Hurricane",
      //   url: "/en-us/cocktails/hurricane/"
      // },
      {
        image: image2,
        imagealt: "FOTY image 2",
        name: "Captain Daiquiri",
        url: "/en-us/cocktails/captain-daiquiri/"
      },
      {
        image: image3,
        imagealt: "FOTY image 3",
        name: "Captain and Cola",
        url: "/en-us/cocktails/captain-and-cola/"
      },
      {
        image: image4,
        imagealt: "FOTY image 4",
        name: "Captain and Cola",
        url: "/en-us/cocktails/captain-and-cola/"
      },
      {
        image: image5,
        imagealt: "FOTY image 5",
        name: "Captain and Cola",
        url: "/en-us/cocktails/captain-and-cola/"
      },
    ]

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          <title>{pageTitle}</title>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>          
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--foty ftc24--fotym" id="main" role="main">
          {(this.state.inGame) ? (
            <GameLayer game={this.state.currentGame} callback={() => this.closeOverlayCallback(this)} that={this} />
          ) : null }
          {/* <CloudLayer background={heroImage} /> */}
          {/* DRIZLY SECTION */}
          <section className="flex heading__section foty__heading">
            <div className="container container--1600">
              <Heading {...this.state.headingProps} />
            </div>
            <div className="container container--1600 z-10 mobile-nopad" id="mapContainer">
              
            </div>
          </section>          
          <section className="flex content heading__section foty__heading">
            <div className="container container--1200">
              <div className="content__wrap flex">                
                <div className="slideswrap__heading">
                  <h2 className="slideswrap__title text--white">
                    2024 Ultimate NFL Fan of the Year
                    <span> Congratulations<br />Megan Stefanski</span>
                  </h2>
                </div>
                <div className="heading__copy text--white text--center ">
                  After tens of thousands of nominations were whittled down to 32 team nominees, it was time to crown the Ultimate NFL Fan of the Year at the 14th Annual NFL Honors Award Show. On behalf of the NFL, Captain Morgan, and all the fans who voted, it is an honor to present Megan Stefanski of the Detroit Lions this award. Megan's passion, dedication, and charitable work make her a worthy pick for this prestigious award.
                </div>
                <div className="content__copy text--copy text--white">
                  {/*Check back in November to see the 32 team nominees and vote for the Ultimate NFL Fan of the Year!*/}
                  <div className="cta__link">
                    <a
                      aria-label="Read More"
                      className="clear btn--ga"
                      data-text="Read More"
                      data-name='Read More'
                      data-path="/honors/fan-of-the-year/2024/lions"
                      data-section='FOTY CTA'
                      data-url="https://www.nfl.com/honors/fan-of-the-year/2024/lions"
                      href="https://www.nfl.com/honors/fan-of-the-year/2024/lions"
                      rel="noreferrer"
                      target="_blank"
                      title="Read More"
                    >
                      <img aria-label="Read More" alt="Read More" className="cta__image" src={btnReadMore} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex">
            <div className="container container--1200">
              <div className="slideswrap">                
                <img aria-label="2024 Ultimate Fan of the Year Megan Stefanski" alt="2024 Ultimate Fan of the Year Megan Stefanski" className="slideswrap__image" src={fotyWinner} />
              </div>
            </div>
          </section>
          <section className="flex trophy">
            <img className="trophy__bg" src={fotyBgWhiteTop} aria-hidden="true" />
            <div className="trophy__wrap">
              <div className="container container--1200">
                <div className="slideswrap">
                  <div className="slideswrap__heading">
                    <h2 className="slideswrap__title trophy__title text--white">
                      Ultimate fan of the Year Trophy
                    </h2>
                  </div>
                  <div className="heading__copy trophy__copy text--white text--center ">
                    Check out this year's custom Ultimate NFL Fan Of The Year trophy that Megan will be taking home. This gold treasure trove is full of symbols celebrating the unique spice of football fandom. If you or someone you know is an extraordinary fan that spices up gameday and wants a chance at this legendary trophy, come back next season and visit <a href="https://www.nfl.com/fanoftheyear" rel="noreferrer" target="_blank">nfl.com/fanoftheyear</a> to nominate.
                  </div>
                  <img aria-label="2024 Ultimate Fan of the Year Trophy" alt="2024 Ultimate Fan of the Year Trophy" className="slideswrap__image trophy__image" src={fotyTrophy} />
                </div>
              </div>
            </div>
            <img className="trophy__bg" src={fotyBgWhiteBot} aria-hidden="true" />
          </section>
          <section className="flex teams">
            <div className="container container--1440">
              <div className="teams__wrap flex text--white">
                <div className="teams__heading">
                  <h2 className="teams__title text--white">
                    Thank you to our 2024 NFL Fans of the Year
                  </h2>
                </div>
                <div className="slideswrap">
                  <div className="slideswrap__wrap">
                    <div className="gallery">
                      <div className="swiper-container gallery-slider">
                        <div className="swiper-wrapper">
                          {galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
                            return (
                              <div key={`gallery_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                                <div className="content-wrap">
                                  <div className="content__block">
                                    <img src={item.image} alt={item.imagealt} className="img-cocktail" />
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>                
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew for an epic adventure, starting with this: Captain Morgan is dropping anchor at Super Bowl LIX!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
