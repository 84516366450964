/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "../page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import GameLayer from "../../game-layer/";
import * as utils from 'global/utils/utils'

import AppError from "../../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../../organisms/NflProgressiveModal";
import Heading from "../../heading";
import HeadingWithCopy from "../../heading-with-copy/";
import Footer from "../../footer";
import PopupLayer from "../../popup-layer/";
import ModalNfl from "../../../../molecules/modal-nfl";
import buildPage from "../../../page-builder";

import NavCustom from "../../nav-custom";

import btnRecipe from 'global/images/ftc2024/btn__recipe.png'


import ftcGlitch from 'global/images/ftc2024/ftc-glitch.png'
import ftcGlitchMobile from 'global/images/ftc2024/ftc-glitch--mobile.png'
import captainRed from 'global/images/ftc2024/captain_red.png'

import cocktailRays from 'global/images/ftc2024/cocktail_rays.png'
import cocktailCptCola from 'global/images/ftc2024/cocktail-captain-cola.png'
import cocktailCptDaq from 'global/images/ftc2024/cocktail-captain-daiquiri.png'
import cocktailHurricane from 'global/images/ftc2024/cocktail-hurricane.png'

import rumStreetBanner from 'global/images/ftc2024/rum_street_logo.png'
import rumStreetMap from 'global/images/ftc2024/rum_street_map.png'
import redeemGold from 'global/images/ftc2024/btn__redeem--gold.png'
import rsvpGold from 'global/images/ftc2024/btn__signup--gold.png'
import joinGold from 'global/images/ftc2024/btn__join--gold.png'
import seeMoreBtn from 'global/images/ftc2024/btn__seemore.png'
import sideBtn from 'global/images/ftc2024/side_prizebtn.png'

import closed1 from 'global/images/ftc2024/closed_1--bordered.png'
import closed2 from 'global/images/ftc2024/closed_2--bordered.png'
import closed3 from 'global/images/ftc2024/closed_3--bordered.png'
import closed4 from 'global/images/ftc2024/closed_4--bordered.png'
import closedBannerLeft from 'global/images/ftc2024/closed_banner--left.jpg'
import closedBannerRight from 'global/images/ftc2024/closed_banner--right.png'

const cn = require('classnames')

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "eyebrow": "Legends start <span class='inline-block'>when you</span>",
      "image": ftcGlitch,
      "imageSize": '',
      "imageMobile": ftcGlitchMobile,
      "imagealt": "Follow the captain",
      "title": "",
      "srText": "follow the captain",
      "copy": "",
    },
    "inGame": true,
    "currentGame": "",
    "modalOpen": false,
    "modalType": '',
    "modalData": {},
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler (isRumStreet = false) {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true,
      pIsRumStreet: isRumStreet
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }
  visitModalCloseHandler (event) {
    const targetId = event.target.id
    const popup = document.getElementById('popupLayer')
    popup.classList.remove('fadeIn')
    setTimeout( () => {
      this.setState({
        visitModalOpen: false
      })
      // if(targetId === 'plEnterNow--image' || targetId === 'plEnterNow') {
      //   setTimeout( () => { this.pModalClickHandler(true) })
      // }
    }, 300)    
  }

  showModal (type, data) {
    const modalData = data ? data : {}
    this.setState({
      "modalOpen": true,
      "modalType": type,
      "modalData": modalData
    })
  }

  closeModal () {
    this.setState({
      modalOpen: false
    })
  }

  initGallery() {
    const gallerySlider = document.getElementsByClassName('gallery-slider')

    if (gallerySlider.length > 0) {
      var slider = new Swiper ('.gallery-slider', {
        slidesPerView: 1,
        // centeredSlides: true,
        loop: true,
        loopedSlides: 6,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
      });
    }
  }
    closeOverlayCallback (that) {
    const gameLayer = document.getElementById('gamelayer')
    const nflPModal = document.getElementById('nflPModal')
    const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
    this.unLockElements()
    if (gameLayer) {
      gameLayer.classList.add('slideOut')
      gameLayer.classList.remove('slideIn')
      nflPModal.classList.remove('z-top')
      if(recaptcha) {
        recaptcha.classList.remove('z-top')
      }
    }
    
    setTimeout(() => {
      this.setState({
        "inGame": false,
        "currentGame": "",
      })
    }, 1000)
  }
  lockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.add('locked')
    bodyElem.classList.add('locked')
    navElem.classList.add('locked')
  }

  unLockElements () {
    const htmlElem = document.getElementsByTagName('html')[0]
    const bodyElem = document.getElementsByTagName('body')[0]
    const navElem = document.getElementById('mainNav')
    htmlElem.classList.remove('locked')
    bodyElem.classList.remove('locked')
    navElem.classList.remove('locked')
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  waitForElem () {
    const gallerySlider = document.getElementsByClassName('gallery-slider')
    if(gallerySlider !== "undefined"){
      setTimeout( () => {
        this.initGallery()
      }, 500)
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }
  componentDidMount () {
    let params = new URL(window.location.href).searchParams
    let showPrizeGame = params.get('showPrizeGame')
    let skipPrizeGame = params.get('skipPrizeGame')
    let skipEpicGame = params.get('skipEpicGame')

    if (skipEpicGame ||skipPrizeGame || showPrizeGame ) {

     // this.clickHandler(true, this, (skipPrizeGame || showPrizeGame) ? 'card' : 'bottle')
    }

    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text') || ''
        var btnHref = $(this).data('url') || ''
        var btnPath = $(this).data('path') || ''
        var btnName = $(this).data('name') || ''
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnPath,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })
    })
    setTimeout( () => { this.waitForElem(), 1500})
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          url: '/followthecaptain',
        },
        {
          title: 'NFL Fans of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
          inactive: true,
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          path: '/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    const galleryData = [
      {
        image: cocktailHurricane,
        imagealt: "A glass of a Hurricane coktail made with Captain Morgan",
        name: "Hurricane",
        url: "/en-us/cocktails/hurricane/"
      },
      {
        image: cocktailCptDaq,
        imagealt: "A glass of Captain Daiquiri",
        name: "Captain Daiquiri",
        url: "/en-us/cocktails/captain-daiquiri/"
      },
      {
        image: cocktailCptCola,
        imagealt: "A glass of Captain and Cola",
        name: "Captain and Cola",
        url: "/en-us/cocktails/captain-and-cola/"
      },
    ]

    const blockData = [
      {
        title: "Captain's cruise with cruz",
        copy: "Captain Morgan took gameday to the high seas with a ‘sailgate’ on the Hudson hosted by Victor Cruz—featuring Captain cocktails, tailgate eats, and an epic Aminé set in front of the NYC skyline.",
        image: closed1,
        galleryId: 'ftc24Cruize',
      },
      {
        title: "KidSuper Merch Drop",
        copy: "Captain Morgan, Super Bowl legend Victor Cruz, and KidSuper dropped something SUPER: a bold crewneck for your crew, unveiled in epic fashion from a giant shipping container in Grand Central.",
        image: closed2,
        galleryId: 'ftc24Merch',
      },
      {
        title: "Rum Street at Super Bowl LIX",
        copy: "Captain Morgan turned Bourbon Street into Rum Street, the unofficial-official most fun destination for Super Bowl LIX, with surprises around every corner and a legendary performance by T-Pain.",
        image: closed3,
        galleryId: 'ftc24RumStreet',
      },
      {
        title: "Epic Prizes All Season Long",
        copy: "Fans Followed the Captain all season long for epic prizes, like legendary game-day experiences, exclusive tickets, signed team swag, and once-in-a-lifetime access to the action on and off the field.",
        image: closed4,
        galleryId: 'ftc24Prizes',
      },
    ]
    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
          entrySource={this.state.pIsRumStreet === true ? "US_CPT_SuperBowl_FY25" : null}
          eventKey={this.state.pIsRumStreet === true ? "APIEvent-2e577fa3-1798-b219-70d4-55b32ee236fb" : null}
          subhead={this.state.pIsRumStreet === true ? "Join our crew to be notified about Super Bowl LIX's most unforgettable party!" : null}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="Enter to win tickets to Super Bowl LIX in New Orleans, once-in-a-lifetime experiences, and epic prizes when you Follow the Captain with Captain Morgan." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="Enter to win tickets to Super Bowl LIX in New Orleans, once-in-a-lifetime experiences, and epic prizes when you Follow the Captain with Captain Morgan." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          
          <title>{pageTitle}</title>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
        </Helmet>
        <div className={cn(this.state.modalOpen === true ? 'modalcustom open' : 'modalcustom')} id="modalcustom">
          <div className="modalcustom__container modalcustom__container--ftc">
            <div className={cn("modalcustom__wrap", this.state.modalType === 'incorrect' ? 'modalcustom__wrap--red' : '', `modalcustom__wrap--${this.state.modalType}`)} id="modalContent">
              <button className="btn btn--close" aria-label="close modal" onClick={() => this.closeModal()}>
                <span className={cn("line line--1", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
                <span className={cn("line line--2", this.state.modalType === 'incorrect' ? 'line--white' : '')}></span>
              </button>
              <ModalNfl type={this.state.modalType} data={this.state.modalData} closemodal={() => this.closeModal()} url={this.state.successUrl} />
            </div>
          </div>
        </div>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--foty ftc24--rumst ftc24--closed" id="main" role="main">
          {(this.state.inGame) ? (
            <GameLayer game={this.state.currentGame} callback={() => this.closeOverlayCallback(this)} that={this} />
          ) : null }
          {/* DRIZLY SECTION */}
          <section className="flex heading__section foty__heading section--heading rstwocol">
            <div className="container container--1600">
              <Heading {...this.state.headingProps} />
              <div className="rstwocol__wrap rstwocol__centered flex text--white">
                <div className="rstwocol__content rstwocol__content--clear">
                  <div className="rstwocol__title">
                    Sign up for our next adventure!
                  </div>
                  <button
                    aria-label="Sign Up"
                    className="btn clear btn--ga"
                    data-text="Sign Up"
                    data-name="Sign Up"
                    data-path=""
                    data-section="Rum Street CTA"
                    data-url=""
                    onClick={() => this.pModalClickHandler()}
                    rel="noreferrer"
                    title="Sign Up"
                  >
                    <img
                      aria-label="Sign Up"
                      alt="Sign Up"
                      className="cta__image"
                      src={joinGold}
                      />
                  </button>
                </div>
              </div>
              <div className="captainimage">
                <img src={captainRed} alt="Captain Morgan Image" aria-hidden="true" />
              </div>
            </div>
          </section>
          <section className="flex content rsmap rstwocol rstwocolc">
            <div className="container container--full">
              <div className="content__wrap flex">
                <div className="rsmap__heading">
                  <h2 className="text--white rumst__heading">
                    Here's Where we led our crew last season
                  </h2>
                </div>
              </div>
            </div>
            <div className="container container--1200">
              <div className="rstwocol__wrap rstwocol__centered flex text--white">
                {blockData.map( (block, key) => {
                  return (
                    <div className="rstwocol__block rstwocol__block--half" key={`hpblock_${key}`}>
                      <div className="rstwocol__image">
                        <img src={block.image} alt="" aria-label="" />
                      </div>
                      <div className="rstwocol__content">
                        <h3 className="rstwocol__title text--black">
                          {block.title}
                        </h3>
                        <div className="rstwocol__copy text--black">
                          {block.copy}
                        </div>
                        <button
                          aria-label="See More"
                          className="btn clear btn--ga"
                          data-text="See More"
                          data-name="See More"
                          data-path=""
                          data-section="Landin Page CTA"
                          data-url=""
                          onClick={() => this.showModal('gallery', {type: block.galleryId})}
                          rel="noreferrer"
                          title="See More"
                        >
                          <img
                            aria-label="See More"
                            alt="See More"
                            className="cta__image"
                            src={seeMoreBtn}
                            />
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div className="rstwocol__block rstwocol__block--full">
                  <div className="fullwrap">
                    <a className="btn--clear" href="#" rel="noreferrer" target="_blank">
                      <img src={closedBannerLeft} alt="One lucky fan even followed the Captain to Super Bowl LIX!" aria-label="One lucky fan even followed the Captain to Super Bowl LIX!" />
                    </a>
                    <a className="btn--clear" href="#" rel="noreferrer" target="_blank">
                      <img src={closedBannerRight} alt="One lucky fan even followed the Captain to Super Bowl LIX!" aria-label="One lucky fan even followed the Captain to Super Bowl LIX!" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex section--gallery">
            <div className="container container--1200">
              <div className="gallery__heading">
                <h2 className="text--red rumst__heading">
                  Can't wait for next season?
                </h2>
                <div className="gallery__copy text--copy">
                  Mix up some adventure by making a Captain cocktail at home.
                </div>
              </div>
              <div className="slideswrap">
                <div className="slideswrap__wrap">
                  <div className="gallery">
                    <div className="swiper-container gallery-slider">
                      <div className="swiper-wrapper">
                        {galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
                          return (
                            <div key={`gallery_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                              <div className="content-wrap">
                                <div className="content__block content__block--left">
                                  <img src={cocktailRays} aria-hidden="true" className="img-bg" />
                                  <img src={item.image} alt={item.imagealt} className="img-cocktail" />
                                </div>
                                <div className="content__block content__block--right">
                                  <div className="content__block--copy">
                                    <h3 className="text--white">{item.name}</h3>
                                    <a
                                      aria-label={`View ${item.name} Recipe`}
                                      className="btn__mapfilter btn__mapfilter--chest btn--ga clear"
                                      data-text={`View ${item.name} Recipe`}
                                      data-name={`View ${item.name} Recipe`}
                                      data-path={item.url}
                                      data-section="gallery"
                                      data-url={`https://www.captainmorgan.com/${item.url}`}
                                      href={item.url}
                                      rel="noreferrer"
                                      title={`View ${item.name} Recipe`}
                                    >
                                      <img src={btnRecipe} aria-label={`View ${item.name} Recipe`} width="200px" height="200px" loading="lazy" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
