/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import moment from "moment";

import * as utils from 'global/utils/utils'

import AppError from "../../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../../organisms/NflProgressiveModal";
import HeadingWithCopy from "../../heading-with-copy/";
import Footer from "../../footer";
import buildPage from "../../../page-builder";

import NavCustom from "../../nav-custom";

import heroImage from 'global/images/ftc2024/banner--rsgiveaway.png'
import heroImageMobile from 'global/images/ftc2024/banner--rsgiveaway-mobile.png'
import rumStreetBanner from 'global/images/ftc2024/rum_street_logo.png'
import btnSubmit from 'global/images/ftc2024/btn__submit.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "eyebrow": "WELCOME TO",
      "image": rumStreetBanner,
      "imageMobile": rumStreetBanner,
      "imagealt": "Captain Morgan Rum Street",
      "title": "",      
      "srText": "Captain Morgan Rum Street",
      "copy": "Bourbon Street’s most iconic cocktails have one thing in common—rum. Captain Morgan is turning Bourbon Street into Rum Street for Super Bowl LIX weekend. Join us for live street art, photo opps, and your ticket to the most epic party of the weekend—Rum Street HQ.",
      "imageSize":"foty",
      "pModalOpen": false,
    },
    "formSubmitted": false,
    "formSubmittedMessage": 'Thank you! We received your submission.',
    "date": '',
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler () {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }

  handleDateChange(e) {
    let value = e.target.value;
    let backsapce = this.state.date.length > value.length;

    if (!backsapce && (this.state.date.length == 1 || this.state.date.length == 4)) {
      value += "/";
    }
    this.setState({
      date: value
    })
  };

  checkAge (dob) {
    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);
    if (birthday.isValid()) {
      if (moment().diff(birthday, "years", false) < 21 || moment().diff(birthday, "years", false) > 110) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  updateFormSubmitted (rejected) {
    let statMessage = ''
    if (rejected) {
      // Create a date object for the target time: February 9th at 2 PM EST
      const targetDate = new Date('2025-02-09T14:00:00-05:00'); // EST is UTC -5

      // Get the current date and time
      const currentDate = new Date();

      // Compare if the current date is greater than the target date
      if (currentDate > targetDate) {
        //Sorry, all “Captain’s Cash” has been claimed. This program has ended.
        statMessage = "Sorry, all “Captain’s Cash” has been claimed. This program has ended.";
      } else {
        statMessage = "Sorry, you are not a winner. Please come back tomorrow and try again at 2:00 p.m. Eastern Time (ET)/1:00 p.m. Central Time (CT) when additional cash is released!";
      }
    } else {
      statMessage = this.state.formSubmittedMessage
    }
    this.setState({
      formSubmitted: true,
      formSubmittedMessage: statMessage,
    })
  }

  async formSubmit(event, that) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#ftcCardGameSubmit').prop('disabled', true).addClass('disabled')
    $('#errorMessage').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });
    event.preventDefault()

    const team = $("#team").find(":selected").val();
    const fname = $("#CP_FirstName").val();
    const lname = $("#CP_LastName").val();
    const email = $("#CP_EmailId").val();
    const dob =  moment($("#CP_DOB").val()).format("YYYY-MM-DD")
    // const city = $("#CP_City").val();
    const zip = $("#CP_ZipCode").val();
    const phone = $("#CP_PhoneNumber").val();
    const state = $("#PR_4_466_1_freetext").find(":selected").val();
    const tc = $("#tc").prop("checked");
    const rules = $("#rules").prop("checked");
    const checkboxFields = $("#PR_1_64_1").prop("checked");
    let captcha = "";

    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        // || city.trim() === "" 
        || state.trim() === "" 
        || phone.trim() === "" 
        || zip.trim() === ""
        || dob.trim() === "" 
        || tc === false || rules === false) {

      if (fname.trim() === "") {
        $("#CP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#CP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#CP_EmailId").addClass("error");
      }
      // if (city.trim() === "") {
      //   $("#CP_City").addClass("error");
      // }
      if (phone.trim() === "") {
        $("#CP_PhoneNumber").addClass("error");
      }
      if (state.trim() === "") {
        $("#PR_4_466_1_freetext").addClass("error");
      }
      if (zip.trim() === "") {
        $("#CP_ZipCode").addClass("error");
      }

      if (dob.trim() === "") {
        $("#CP_DOB").addClass("error");
      }
      formError = true
    }
     //check age
    ageError = this.checkAge(dob)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('ftcCardGame'));
    
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
            // eslint-disable-next-line radix
            send[key] = parseInt(value);
        } else if (key === "PR_4_92_1" || key === "tc") {
          if (value === "on") {
              send[key] = "1";
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "CP_DOB") {
          send[key] = moment($("#CP_DOB").val()).format("YYYY-MM-DD")
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          send[key] = value;
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);

      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const apiUrl = '/api/ftc2024/freedrink'
          const res = await fetch(`${apiUrl}`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          if(res && res.success) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
            });
            window.dataLayer.push({
              "event": "sign_up",
              "step":"success",
              "sign_up_type": "competition",
              "optin_brands": "Captain Morgan",
              "form_name": "Follow The Captain Card Game",
              "form_location": 'modal',
              "detail": checkboxFields? "newsletter opt-in" : "newsletter opt-out"
            })


            that.updateFormSubmitted(res.isRejected || false)
          }else{
            const message = res && res.data && res.data.detail ? res.data.detail : 'Something went wrong. Please try again.'
            $('#errorMessage').html('<div>' + message + '</div>')
            $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          const message = 'Something went wrong. Please try again.'
          $('#errorMessage').html('<div>' + message + '</div>')
          if($('#ftcCardGameSubmit')) {
            $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        let errorMsg = "";

        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          // || city.trim() === ""
          || state.trim() === ""
          || phone.trim() === ""
          || zip.trim() === ""
          || dob.trim() === "") {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 21 and over to enter.</div>";
        }

        if (tc === false) {
          errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
        }
        if (rules === false) {
          errorMsg += "<div>Please accept the official rules.</div>";
        }

        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#ftcCardGameSubmit').prop('disabled', false).removeClass('disabled')
        $('#errorMessage').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody ftcBody--rsfd')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text') || ''
        var btnHref = $(this).data('url') || ''
        var btnPath = $(this).data('path') || ''
        var btnName = $(this).data('name') || ''
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnPath,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })

      const recaptcha = document.getElementsByClassName('grecaptcha-badge')[0]
      if(recaptcha) {
        recaptcha.classList.add('z-top')
      }
    })
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "Captain Morgan Rum Street Giveaway | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          inactive: true,
          url: '/followthecaptain/about',
        },
        {
          title: 'Rum Street',
          url: '/followthecaptain/rum-street',
          inactive: false,
        },
        {
          title: 'NFL Fans of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
          inactive: true,
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          path: '/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          
          <title>{pageTitle}</title>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
          <script>
            {`window.fwSettings={
              'widget_id': 69000003531
            };

            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
          </script>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--rumstfd" id="main" role="main">
          {/* <CloudLayer background={heroImage} /> */}
          <header className="hero">
            <div className="hero__background" role="img" aria-label="Hero Background Image">
              <img src={heroImage} aria-hidden="true" className="hero__image hero__image--desktop" />
              <img src={heroImageMobile} aria-hidden="true" className="hero__image hero__image--mobile" />
            </div>
            <div className="hero__content">
              <h1 className="hero__title gold_font sr-only">Giveaway</h1>
            </div>
          </header>
          <section className="cardgame flex content rsmap">
            <div className="cardgame__winwrap text--white win-wrap winwrap" id="winwrap">
              <div className="winwrap__heading">
                <div className="winwrap__headingcopy text--copy">
                  <p>Captain Morgan is bringing the celebration to Super Bowl LIX! Here's your chance to enjoy the spirit of Nola while supporting your favorite local bars and restaurants on Bourbon Street!
                  </p>
                  <p>Whether you’re toasting with friends or discovering your new go-to cocktail, Captain’s got you covered. Legendary adventures start when you Follow The Captain!
                  </p>
                  <p className="disclaimer">
                    Spending at a local bar/restaurant is SUGGESTED and not required.<br />
                    **NO PURCHASE NECESSARY. Must be legal U.S. resident, 21 years or older with mobile device w/ text messaging capability, e-mail address & U.S.-based bank account. Sweepstakes ends 11:59:59 p.m. ET/10:59:59 p.m. CT on 2/9, subject to prize availability. Winners – SUBJECT TO VERIFICATION -- are the 1st 3340 entrants on 2/7; the 1st 3330 entrants on 2/8 & the 1st 3330 entrants on 2/9. Prizes ONLY awarded as mobile payment.  Unclaimed prizes may not be awarded. Subject to Official Rules at <a href="/captaincash-rules.pdf" target="_blank">CAPTAINMORGAN.COM/FOLLOWTHECAPTAIN/RUM-STREET/CAPTAINCASH</a>
                  </p>
                </div>
              </div>
              <div className="winwrap__content wincontent flex">
                <div className="wincontent__form form">
                  <div className="form__wrap text--white gameform">
                    {this.state.formSubmitted &&
                      <h3 className="columns__heading">
                        {this.state.formSubmittedMessage}
                      </h3>
                    }
                    {!this.state.formSubmitted &&
                      <form className="flex" id="ftcCardGame">
                        <div className="inputWrapper half">
                          <div className="">
                            <label htmlFor="CP_FirstName">First Name *</label>
                            <input id="CP_FirstName" name="CP_FirstName" type="text" className="claimInput" placeholder="First Name" required />
                          </div>

                          <div className="">
                            <label htmlFor="CP_LastName">Last Name *</label>
                            <input id="CP_LastName" name="CP_LastName" type="text" className="claimInput" placeholder="Last Name" required />
                          </div>
                        </div>

                        <div className="inputWrapper half">
                          <div className="">
                            <label htmlFor="CP_EmailId">Email *</label>
                            <input id="CP_EmailId" name="CP_EmailId" type="email" className="claimInput" placeholder="Email" required />
                          </div>

                          <div className="">
                            <label htmlFor="CP_DOB">Date of Birth *</label>
                            <input id="CP_DOB" name="CP_DOB" type="text" className="claimInput" required  value={this.state.date}
                              maxLength={10}
                              onChange={(e) => this.handleDateChange(e)}
                              placeholder="mm/dd/yyyy"/>
                          </div>
                        </div>

                        <div className="inputWrapper half">
                          <div className="">
                            <label htmlFor="CP_PhoneNumber">Phone Number *</label>
                            <input id="CP_PhoneNumber" name="CP_PhoneNumber" maxLength={10} className="claimInput" placeholder="Phone Number" type="tel" required />
                          </div>
                          <div className="">
                            <label htmlFor="PR_4_466_1_freetext">State *</label>
                            <select name="PR_4_466_1_freetext" id="PR_4_466_1_freetext" className="claimInput input w-input" defaultValue="" required>
                              <option disabled value="">State*</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          {/*<div className="">
                            <label htmlFor="CP_City">City *</label>
                            <input id="CP_City" name="CP_City" className="claimInput" placeholder="City" type="text" required />
                          </div>*/}
                        </div>

                        <div className="inputWrapper half">
                          <div className="">
                            <label htmlFor="CP_ZipCode">Zip Code *</label>
                            <input id="CP_ZipCode" name="CP_ZipCode" type="text" maxLength={10} className="claimInput" placeholder="Zip Code" required />
                          </div>
                          <div />
                        </div>

                        <div className="inputWrapper checkbox">
                          <input id="tc" name="tc" type="checkbox" className="claimInput cliamInput--checkbox" required />
                          <label htmlFor="tc" dangerouslySetInnerHTML={{
                            __html: `I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a> of this website. *`
                            }}
                          />
                        </div>

                        <div className="inputWrapper checkbox">
                          <input id="rules" name="rules" type="checkbox" className="claimInput cliamInput--checkbox" required />
                          <label htmlFor="rules" dangerouslySetInnerHTML={{
                            __html: `I have read and agree to the <a href='/captaincash-rules.pdf' target='_blank' style='text-decoration:underline;'">Official Rules</a> of this sweepstakes. *`
                            }}
                          />
                        </div>

                        <div className="inputWrapper checkbox">
                          <input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" className="claimInput cliamInput--checkbox" required />
                          <label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                            __html: `Tick here if you would like us to use your email to keep you informed about products, services and events from Captain Morgan & other Diageo brands including TheBar.com. You can unsubscribe at any time.<br /><br />By signing up you accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a>.`
                            }}
                          />
                        </div>

                        <div className="inputWrapper">
                          <div className="errorMessage" id="errorMessage"></div>
                        </div>
                        <div className="inputWrapper half justify-center">
                          <button aria-label="submit" className="submit btn btn--submit btn--reset" type="submit" id='ftcCardGameSubmit' onClick={(event) => this.formSubmit(event, this)}>
                            <img src={btnSubmit} aria-hidden="true" />
                          </button>
                        </div>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew to get notified of legendary celebrations throughout the season!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
