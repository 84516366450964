/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../../organisms/app-error";
import NflProgressiveModal from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import Footer from "../footer";
import buildPage from "../../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../nav-custom";

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }

  componentDidMount () {
    function waitForElem () {
      if(typeof anyroad !== "undefined" && typeof AnyRoad !== "undefined"){
        initAnyRoad()
      }else{
        setTimeout( () => { waitForElem(), 1500})
      }
    }

    function initAnyRoad () {
      window.anyroad = new AnyRoad({

        container: '#iframe_wrapper',

        plugin: { id: 'captainmorgan_rumstreetparty' }

      });
    }

    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }
      waitForElem()
    })
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "RSVP | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          inactive: true,
          url: '/followthecaptain/about',
        },
        {
          title: 'Rum Street',
          url: '/followthecaptain/rum-street',
        },
        {
          title: 'NFL Fan of the Year',
          inactive: true,
          url: '/followthecaptain/nfl-fan-of-the-year',
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab:true
        },
      ]
    }

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />
          
          <script src="https://app.anyroad.com/assets/integration-v1.1.js" id="anyroad" async />
  
          <title>{pageTitle}</title>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--rsvp" id="main" role="main">   
          <section className="flex rsvp content flex">
            <div className="content__wrap container container--1200 flex">
              <div className="contentblock flex">
                <div className="iframe_wrapper" id="iframe_wrapper" />
                <a
                  id="powered_by_anyroad_link"
                  href="https://www.anyroad.com?utm_source=captainmorgan_rumstreetparty&utm_medium=PBA&utm_campaign=PLUGIN"
                  target="_blank"
                >
                  Powered by AnyRoad
                </a>
              </div>
            </div>
          </section>
        </main>        
        <Footer />
      </div>
    );
  }
}
