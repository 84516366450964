import "./content-gallery.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";


import $ from 'jquery'

/*** PAST EVENT 2023 ***/
import past20231 from 'global/images/ftc2024/events/past_2023_1.jpg'
import past20232 from 'global/images/ftc2024/events/past_2023_2.jpg'
import past20233 from 'global/images/ftc2024/events/past_2023_3.jpg'
import past20234 from 'global/images/ftc2024/events/past_2023_4.jpg'
import past20235 from 'global/images/ftc2024/events/past_2023_5.jpg'
import past20236 from 'global/images/ftc2024/events/past_2023_6.jpg'
import past20237 from 'global/images/ftc2024/events/past_2023_7.jpg'
/*** END PAST EVENT 2023 ***/

/*** 2024 CRUIZE ***/
import ftc24Closed11 from 'global/images/ftc2024/closed/ftc24__closed1--1.jpg'
import ftc24Closed12 from 'global/images/ftc2024/closed/ftc24__closed1--2.jpg'
import ftc24Closed13 from 'global/images/ftc2024/closed/ftc24__closed1--3.jpg'
import ftc24Closed14 from 'global/images/ftc2024/closed/ftc24__closed1--4.jpg'
import ftc24Closed15 from 'global/images/ftc2024/closed/ftc24__closed1--5.jpg'
import ftc24Closed16 from 'global/images/ftc2024/closed/ftc24__closed1--6.jpg'
import ftc24Closed17 from 'global/images/ftc2024/closed/ftc24__closed1--7.jpg'
import ftc24Closed18 from 'global/images/ftc2024/closed/ftc24__closed1--8.jpg'
import ftc24Closed19 from 'global/images/ftc2024/closed/ftc24__closed1--9.jpg'
import ftc24Closed110 from 'global/images/ftc2024/closed/ftc24__closed1--10.jpg'
import ftc24Closed111 from 'global/images/ftc2024/closed/ftc24__closed1--11.jpg'
/*** END 2024 CRUIZE ***/

/*** 2024 MERCH ***/
import ftc24Closed21 from 'global/images/ftc2024/closed/ftc24__closed2--1.jpg'
import ftc24Closed22 from 'global/images/ftc2024/closed/ftc24__closed2--2.jpg'
import ftc24Closed23 from 'global/images/ftc2024/closed/ftc24__closed2--3.jpg'
import ftc24Closed24 from 'global/images/ftc2024/closed/ftc24__closed2--4.jpg'
import ftc24Closed25 from 'global/images/ftc2024/closed/ftc24__closed2--5.jpg'
import ftc24Closed26 from 'global/images/ftc2024/closed/ftc24__closed2--6.jpg'
import ftc24Closed27 from 'global/images/ftc2024/closed/ftc24__closed2--7.jpg'
import ftc24Closed28 from 'global/images/ftc2024/closed/ftc24__closed2--8.jpg'
import ftc24Closed29 from 'global/images/ftc2024/closed/ftc24__closed2--9.jpg'
import ftc24Closed210 from 'global/images/ftc2024/closed/ftc24__closed2--10.jpg'
import ftc24Closed211 from 'global/images/ftc2024/closed/ftc24__closed2--11.jpg'
/*** END 2024 MERCH ***/

/*** 2024 RUMSTREET ***/
import ftc24Closed31 from 'global/images/ftc2024/closed/ftc24__closed3--1.jpg'
import ftc24Closed32 from 'global/images/ftc2024/closed/ftc24__closed3--2.jpg'
import ftc24Closed33 from 'global/images/ftc2024/closed/ftc24__closed3--3.jpg'
import ftc24Closed34 from 'global/images/ftc2024/closed/ftc24__closed3--4.jpg'
import ftc24Closed35 from 'global/images/ftc2024/closed/ftc24__closed3--5.jpg'
import ftc24Closed36 from 'global/images/ftc2024/closed/ftc24__closed3--6.jpg'
import ftc24Closed37 from 'global/images/ftc2024/closed/ftc24__closed3--7.jpg'
import ftc24Closed38 from 'global/images/ftc2024/closed/ftc24__closed3--8.jpg'
import ftc24Closed39 from 'global/images/ftc2024/closed/ftc24__closed3--9.jpg'
import ftc24Closed310 from 'global/images/ftc2024/closed/ftc24__closed3--10.jpg'
import ftc24Closed311 from 'global/images/ftc2024/closed/ftc24__closed3--11.jpg'
/*** END 2024 RUMSTREET ***/

/*** 2024 PRIZES ***/
import ftc24Closed41 from 'global/images/ftc2024/closed/ftc24__closed4--1.jpg'
import ftc24Closed42 from 'global/images/ftc2024/closed/ftc24__closed4--2.jpg'
import ftc24Closed43 from 'global/images/ftc2024/closed/ftc24__closed4--3.jpg'
import ftc24Closed44 from 'global/images/ftc2024/closed/ftc24__closed4--4.jpg'
import ftc24Closed45 from 'global/images/ftc2024/closed/ftc24__closed4--5.jpg'
import ftc24Closed46 from 'global/images/ftc2024/closed/ftc24__closed4--6.jpg'
import ftc24Closed47 from 'global/images/ftc2024/closed/ftc24__closed4--7.jpg'
import ftc24Closed48 from 'global/images/ftc2024/closed/ftc24__closed4--8.jpg'
import ftc24Closed49 from 'global/images/ftc2024/closed/ftc24__closed4--9.jpg'
import ftc24Closed410 from 'global/images/ftc2024/closed/ftc24__closed4--10.jpg'
import ftc24Closed411 from 'global/images/ftc2024/closed/ftc24__closed4--11.jpg'
/*** END 2024 PRIZES ***/

const ContentGallery = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const contentData = getData(pageType)
  const [activeIndex, setActiveIndex] = useState(0);

  const groups = document.getElementsByClassName("card-group");

  function handlePrevious () {
    const nextIndex = activeIndex + 1 <= groups.length - 1 ? activeIndex + 1 : 0;
    
    const currentGroup = document.querySelector(`[data-index="${activeIndex}"]`),
          nextGroup = document.querySelector(`[data-index="${nextIndex}"]`);
          
    currentGroup.dataset.status = "after";
    
    nextGroup.dataset.status = "becoming-active-from-before";
    
    setTimeout(() => {
      nextGroup.dataset.status = "active";
      setActiveIndex(prev => nextIndex);
    });
  }

  function handleNext () {
    const nextIndex = activeIndex - 1 >= 0 ? activeIndex - 1 : groups.length - 1;
    
    const currentGroup = document.querySelector(`[data-index="${activeIndex}"]`),
          nextGroup = document.querySelector(`[data-index="${nextIndex}"]`);
    
    currentGroup.dataset.status = "before";
    
    nextGroup.dataset.status = "becoming-active-from-after";
    
    setTimeout(() => {
      nextGroup.dataset.status = "active";
      setActiveIndex(prev => nextIndex);
    });
  }

  function getData (pageType) {
    const data = [
      {
        comp: 'past2023',
        data: [          
          {
            image: past20231,
            imagealt: "Follow the Captain 2023 Image 1"
          },
          {
            image: past20232,
            imagealt: "Follow the Captain 2023 Image 2"
          },
          {
            image: past20233,
            imagealt: "Follow the Captain 2023 Image 3"
          },
          {
            image: past20234,
            imagealt: "Follow the Captain 2023 Image 4"
          },
          {
            image: past20235,
            imagealt: "Follow the Captain 2023 Image 5"
          },
          {
            image: past20236,
            imagealt: "Follow the Captain 2023 Image 6"
          },
          {
            image: past20237,
            imagealt: "Follow the Captain 2023 Image 7"
          },
        ]
      },
      {
        comp: 'ftc24Cruize',
        data: [
          {
            image: ftc24Closed11,
            imagealt: "Captain's Cruize with Cruz Image 1"
          },
          {
            image: ftc24Closed12,
            imagealt: "Captain's Cruize with Cruz Image 2"
          },
          {
            image: ftc24Closed13,
            imagealt: "Captain's Cruize with Cruz Image 3"
          },
          {
            image: ftc24Closed14,
            imagealt: "Captain's Cruize with Cruz Image 4"
          },
          {
            image: ftc24Closed15,
            imagealt: "Captain's Cruize with Cruz Image 5"
          },
          {
            image: ftc24Closed16,
            imagealt: "Captain's Cruize with Cruz Image 6"
          },
          {
            image: ftc24Closed17,
            imagealt: "Captain's Cruize with Cruz Image 7"
          },
          {
            image: ftc24Closed18,
            imagealt: "Captain's Cruize with Cruz Image 8"
          },
          {
            image: ftc24Closed19,
            imagealt: "Captain's Cruize with Cruz Image 9"
          },
          {
            image: ftc24Closed110,
            imagealt: "Captain's Cruize with Cruz Image 10"
          },
          {
            image: ftc24Closed111,
            imagealt: "Captain's Cruize with Cruz Image 11"
          },
        ]
      },
      {
        comp: 'ftc24Merch',
        data: [
          {
            image: ftc24Closed21,
            imagealt: "Kidsuper Merch Drop Image 1"
          },
          {
            image: ftc24Closed22,
            imagealt: "Kidsuper Merch Drop Image 2"
          },
          {
            image: ftc24Closed23,
            imagealt: "Kidsuper Merch Drop Image 3"
          },
          {
            image: ftc24Closed24,
            imagealt: "Kidsuper Merch Drop Image 4"
          },
          {
            image: ftc24Closed25,
            imagealt: "Kidsuper Merch Drop Image 5"
          },
          {
            image: ftc24Closed26,
            imagealt: "Kidsuper Merch Drop Image 6"
          },
          {
            image: ftc24Closed27,
            imagealt: "Kidsuper Merch Drop Image 7"
          },
          {
            image: ftc24Closed28,
            imagealt: "Kidsuper Merch Drop Image 8"
          },
          {
            image: ftc24Closed29,
            imagealt: "Kidsuper Merch Drop Image 9"
          },
          {
            image: ftc24Closed210,
            imagealt: "Kidsuper Merch Drop Image 10"
          },
          {
            image: ftc24Closed211,
            imagealt: "Kidsuper Merch Drop Image 11"
          },
        ]
      },
      {
        comp: 'ftc24RumStreet',
        data: [
          {
            image: ftc24Closed31,
            imagealt: "Rum Street at Super Bowl LIX Image 1"
          },
          {
            image: ftc24Closed32,
            imagealt: "Rum Street at Super Bowl LIX Image 2"
          },
          {
            image: ftc24Closed33,
            imagealt: "Rum Street at Super Bowl LIX Image 3"
          },
          {
            image: ftc24Closed34,
            imagealt: "Rum Street at Super Bowl LIX Image 4"
          },
          {
            image: ftc24Closed35,
            imagealt: "Rum Street at Super Bowl LIX Image 5"
          },
          {
            image: ftc24Closed36,
            imagealt: "Rum Street at Super Bowl LIX Image 6"
          },
          {
            image: ftc24Closed37,
            imagealt: "Rum Street at Super Bowl LIX Image 7"
          },
          {
            image: ftc24Closed38,
            imagealt: "Rum Street at Super Bowl LIX Image 8"
          },
          {
            image: ftc24Closed39,
            imagealt: "Rum Street at Super Bowl LIX Image 9"
          },
          {
            image: ftc24Closed310,
            imagealt: "Rum Street at Super Bowl LIX Image 10"
          },
          {
            image: ftc24Closed311,
            imagealt: "Rum Street at Super Bowl LIX Image 11"
          },
        ]
      },
      {
        comp: 'ftc24Prizes',
        data: [
          {
            image: ftc24Closed41,
            imagealt: "Epic prizes all season long Image 1"
          },
          {
            image: ftc24Closed42,
            imagealt: "Epic prizes all season long Image 2"
          },
          {
            image: ftc24Closed43,
            imagealt: "Epic prizes all season long LIX Image 3"
          },
          {
            image: ftc24Closed44,
            imagealt: "Epic prizes all season long LIX Image 4"
          },
          {
            image: ftc24Closed45,
            imagealt: "Epic prizes all season long LIX Image 5"
          },
          {
            image: ftc24Closed46,
            imagealt: "Epic prizes all season long LIX Image 6"
          },
          {
            image: ftc24Closed47,
            imagealt: "Epic prizes all season long LIX Image 7"
          },
          {
            image: ftc24Closed48,
            imagealt: "Epic prizes all season long LIX Image 8"
          },
          {
            image: ftc24Closed49,
            imagealt: "Epic prizes all season long LIX Image 9"
          },
          {
            image: ftc24Closed410,
            imagealt: "Epic prizes all season long LIX Image 10"
          },
          {
            image: ftc24Closed411,
            imagealt: "Epic prizes all season long LIX Image 11"
          },
        ]
      }
    ]

    if(pageType !== '') {
      const contentItems = data.filter(x => x.comp === pageType)[0]
      if(contentItems) {
        return contentItems.data
      }else{
        return null
      }
    }else{
      return null
    }
  }

  
  useEffect(() => {
    // const thumbs = new Swiper ('.gallery-thumbs', {
    //   slidesPerView: 'auto',
    //   spaceBetween: 10,
    //   centeredSlides: true,
    //   loop: true,
    //   slideToClickedSlide: true,
    //   modules: [Thumbs, Navigation, FreeMode],
    //   watchSlidesProgress: true,
    // });
    // const slider = new Swiper('.gallery-slider', {
    //   slidesPerView: 1,
    //   centeredSlides: true,
    //   slideToClickedSlide: true,
    //   loop: true,
    //   loopedSlides: 6,
    //   navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //   },
    //   modules: [Thumbs, Navigation, FreeMode],
    //   thumbs: {swiper: thumbs},
    //   watchSlidesProgress: true,
    // })
    // console.log("SLIDER > ", slider)
    $(function () {
      var thumbs = new Swiper ('.gallery-thumbs', {
         slidesPerView: 'auto',
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          loop: true,
          spaceBetween: 10
      });
      var slider = new Swiper ('.gallery-slider', {
          slidesPerView: 1,
          // centeredSlides: true,
          loop: true,
          loopedSlides: 6,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: thumbs
          }
        });
      // slider.controller.control = thumbs;
      // thumbs.controller.control = slider;
    })
  })
  return (
    <div className="gallery">
      <div className="swiper-container gallery-slider">
        <div className="swiper-wrapper">
          {contentData && contentData.length > 0 && contentData.map((item, key) => {
            return (
              <div key={`gallery_${pageType}_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                 <img src={item.image} alt={item.imagealt} />
                <div className="content-wrap">
                
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="swiper-container gallery-thumbs">
        <div className="swiper-wrapper">
          {contentData && contentData.length > 0 && contentData.map((item, key) => {
            return (
              <div key={`gallery_thumb_${pageType}_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                <div className="content-wrap">
                  <img src={item.image} alt={item.imagealt} />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
};

export default ContentGallery;
