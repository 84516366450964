import "./style.scss";
import React from "react";

import rumStreet from 'global/images/ftc2024/popup_image_rumstreet.jpg'
import btnBack from 'global/images/ftc2024/btn__backtomap.png'
import btnPlay from 'global/images/ftc2024/btn__signup.png'

const PopupLayer = (props) => {
  return (
    <div className="popuplayer" tabIndex="1" id="popupLayer">
      <div className="popuplayer__container">
        <button
          className="btn backtomap"
          onClick={(e) => props.closeHandler(e)}
        >
          <img src={btnBack} alt="Back to map" aria-label="Back to map" />
          <span className="sr-only">{`<`} BACK TO MAP</span>
        </button>
        <div className="popuplayer__wrap">
          <div className="popuplayer__image">
            <img src={rumStreet} aria-hidden="true" />
          </div>
          <div className="popuplayer__title">
            Join us on Rum Street!
          </div>
          <div className="popuplayer__copy">
            Captain Morgan is bringing adventure to <span className="inline-block">Super Bowl LIX</span> on <span className="strikethrough">Bourbon</span> Rum Street.<br /><br />
            Sign up to hear how you can join the crew for a weekend of epic experiences right in the heart of New Orleans!
          </div>
          <div className="popuplayer__cta">
            {/*<button 
              className="btn btn--reset btn--play"
              id="plEnterNow"
              onClick={(e) => props.closeHandler(e)}
            >
              <img src={btnPlay} alt="Enter Now" aria-label="Enter Now" id="plEnterNow--image" />
            </button>*/}
            <button 
              className="btn btn--reset btn--play"
              id="plEnterNow"
              onClick={(e) => props.closeHandler(e)}
            >
              <img src={btnPlay} alt="Enter Now" aria-label="Enter Now" id="plEnterNow--image" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLayer;
