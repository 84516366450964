import "./style.scss";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import iconChatbot from 'global/images/chatbot/icon--chatbot.svg'
import iconChatbotWhite from 'global/images/chatbot/icon--chatbot--white.svg'
import styledBg from 'global/images/chatbot/styled_title_bg.png'


const cn = require('classnames')

const BannerBlock = (props) => {
  if (props && props.fields) {
    const {
      BlockTitle,
      YouTubeUrl,
    } = fieldsArrayToKeyValueHash(props.fields);

    return (
      <section className="ytblock">
        <div className="container container--1200">
          <div className="ytblock__wrap">
            {BlockTitle ? (
              <h2>{BlockTitle}</h2>
            ) : null}
            <iframe width="100%" height="auto" src={YouTubeUrl} title={BlockTitle && BlockTitle !== '' ? BlockTitle : 'YouTube Video'} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          </div>
        </div>
      </section>
    );

  }

  return false;
};

export default BannerBlock;
