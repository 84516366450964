import "./two-content-promo.scss";
import ContentCard from "../../molecules/content-card";
import Cta from "../../atoms/cta";
import ProductCard from "../../molecules/product-card";
import React from "react";
import RecipeCard from "../../molecules/recipe-card";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

// Combines all two column content promo types
// From separate content components in the CMS
// Does not currently return form API as top level component
const TwoContentPromo = (props) => {

    const {
        Heading,
        CtaCollection,
    } = fieldsArrayToKeyValueHash(props.fields);

    const Summary = props.fields.find((card) => card.title === "Intro" || card.title === "Strapline");

    const cards = props.fields.filter((card) => card.title === "ProductCard" || card.title === "ContentCard");

    if (!cards || cards.length <= 0) {

        return null;

    }

    const getImageUrl = (image) => {

        const cardImage = fieldsArrayToKeyValueHash(image);

        if (cardImage.Url) {

            return cardImage.Url;

        }

        return "";

    };

    const renderCardType = (card, position) => {
        const {
            CardImage,
            CardTitle,
            CardSummary,
            DocumentTypeAlias,
            Url,
            CardCtaText,
            NewWindow,
            BackgroundColour,
            Tags,
        } = fieldsArrayToKeyValueHash(card.fields);

        let cardImageUrl = "";

        if (CardImage) {

            cardImageUrl = getImageUrl(CardImage);

        }

        if (!CardTitle || !Url) {

            return null;

        }

        if (DocumentTypeAlias === "product") {

            return (
                <li key={`card-${position}`} className="two-content-promo__listing-item">
                    <ProductCard
                        background={BackgroundColour}
                        copy={CardSummary}
                        ctaText={CardCtaText}
                        heading={CardTitle}
                        image={cardImageUrl}
                        url={Url}
                        newWindow={NewWindow}
                    />
                </li>
            );

        } else if (DocumentTypeAlias === "recipe") {

            return (
                <li key={`card-${position}`} className="two-content-promo__listing-item">
                    <RecipeCard
                        copy={CardSummary}
                        heading={CardTitle}
                        image={cardImageUrl}
                        newWindow={NewWindow}
                        url={Url}
                    />
                </li>
            );

        }

        return (
            <li key={`card-${position}`} className="two-content-promo__listing-item">
                <ContentCard
                    background={BackgroundColour}
                    copy={CardSummary}
                    ctaText={CardCtaText}
                    heading={CardTitle}
                    image={cardImageUrl}
                    url={Url}
                    newWindow={NewWindow}
                />
            </li>
        );

    };
      let showCards = false

    if (!cards || cards.length <= 0) {

        return null;

    }
    cards.forEach( (card, index) => {
      const cardField = card && card.fields ? card.fields : []

      if(cardField.length > 0) {
        const {
            CardImage,
            CardTitle,
            CardCtaText,
            Url,
            NewWindow,
        } = fieldsArrayToKeyValueHash(card.fields);
        let cardImageUrl = "";

        if (CardImage) {

            cardImageUrl = getImageUrl(CardImage);

        }
        if (
            !cardImageUrl ||
            !CardTitle ||
            !Url
        ) {
          showCards = false
        }else{
          showCards = true
        }
      }else{
        if(showCards === true){
          showCards = true
        }else{
          showCards = false
        }
      }
    })
    if(showCards === false) {
      return null
    }

    return (
        <section className="two-content-promo">
            <div className="two-content-promo__inner">
                {
                    Heading &&
                        <header className="two-content-promo__header">
                            <h2 className="two-content-promo__title ">{Heading}</h2>
                            {
                                Summary &&
                                    <div
                                        className="two-content-promo__summary"
                                        dangerouslySetInnerHTML={{__html: sanitise(Summary.value)}}>
                                    </div>
                            }
                        </header>
                }
                <ul className="two-content-promo__listing">
                    {
                        cards.map((card, index) => renderCardType(card, index))
                    }
                </ul>
                {
                    CtaCollection &&
                        <div className="two-content-promo__ctas">
                            {
                                CtaCollection.map((cta, index) => <Cta key={`cta-${index}`} cta={cta} />)
                            }
                        </div>
                }
            </div>
        </section>
    );

};

export default TwoContentPromo;
