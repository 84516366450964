import "./hero.scss";
import Cta from "../../atoms/cta";
import HeroBackground from "../../molecules/hero-background";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

class Hero extends React.Component {

    render () {

        const {
            HeroSummary,
            HeroTitle,
            HeroTitleColor,
            CtaCollection,
            Image,
            HideTitle,
        } = fieldsArrayToKeyValueHash(this.props.fields);
        
        return (
            <header className="hero">
                {/*<img
                   className="hero__accent"
                   src={require("../../../global/images/backgrounds/gold-1.png")}
                />
                <img
                    className="hero__emboss"
                    src={require("../../../global/images/backgrounds/emboss.png")}
                />*/}
                <HeroBackground image={Image} />
                <div className="hero__content">
                    <h1 className={`hero__title ${HeroTitleColor === '' || HeroTitleColor === 'gold' ? 'gold_font' : `hero__title--${HeroTitleColor}`} ${HideTitle ? 'sr-only' : ''}`}>{HeroTitle}</h1>
                    {
                        HeroSummary &&
                            <div
                                className="hero__summary"
                                dangerouslySetInnerHTML={{__html: sanitise(HeroSummary)}}
                            />
                    }
                    {
                        CtaCollection &&
                            CtaCollection.map((cta, index) => <Cta key={`cta-${index}`} cta={cta}/>)
                    }
                </div>
            </header>
        );

    }

}

export default Hero;
