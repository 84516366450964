import BackToTopBanner from "../../components/organisms/back-to-top-banner";
import AccordionBlock from "../../components/organisms/accordion-block";
import BannerBlock from "../../components/organisms/banner-block";
import YouTubeBlock from "../../components/organisms/you-tube-block";
import TwoColumnBlock from "../../components/organisms/two-column-block";
import HeadingWithContent from "../../components/organisms/heading-with-content";
import BuyBanner from "../../components/organisms/buy-banner";
import BuyProductPromo from "../../components/organisms/buy-product-promo";
import CampaignPromo from "../../components/organisms/campaign-promo";
import ContentPromo from "../../components/organisms/content-promo";
import ContentTiles from "../../components/organisms/content-tiles";
import DrinkAwareBanner from "../../components/organisms/drink-aware-banner";
import EventList from "../../components/organisms/event-list";
import FacebookFeed from "../../components/organisms/facebook-feed";
import FollowUs from "../../components/organisms/follow-us";
import ImageHero from "../../components/organisms/hero/hero--image";
import Ingredients from "../../components/organisms/recipe-hero/ingredients";
import MarketSwitcher from "../../components/organisms/market-switcher";
import Meta from "../../components/organisms/meta";
import Method from "../../components/organisms/recipe-hero/method";
import Navigation from "../../components/organisms/navigation";
import ProductHero from "../../components/organisms/product-hero";
import ProductList from "../../components/organisms/product-list";
import ProductStory from "../../components/organisms/product-story";
import PurchaseIntegration from "../../components/organisms/purchase-integration";
import React from "react";
import RecipeAndFilterList from "../../components/organisms/recipe-and-filter-list";
import RecipeCard from "../../components/organisms/recipe-promo";
import RecipeHero from "../../components/organisms/recipe-hero";
import SocialSharing from "../../components/organisms/social-sharing";
import StructuredData from "../../components/organisms/structured-data";
import TextBlock from "../../components/organisms/text-block";
import ThreeProductPromo from "../../components/organisms/three-product-promo";
import TwoColContentCard from "../../components/organisms/two-col-content-card";
import TwoProductPromo from "../../components/organisms/two-product-promo";
import Video from "../../components/organisms/video";
import VideoHero from "../../components/organisms/hero/hero--video";
import WhereToBuy from "../../components/organisms/where-to-buy";

const HeaderComponents = {
    Meta,
    Navigation,
    PurchaseIntegration,
    StructuredData,
};
const FooterComponents = {
    BackToTopBanner,
    FollowUs,
    MarketSwitcher,
    SocialSharing,
};

export {HeaderComponents};
export {FooterComponents};

export default {
    BackToTopBanner,
    AccordionBlock,
    BannerBlock,
    YouTubeBlock,
    TwoColumnBlock,
    HeadingWithContent,
    BuyBanner,
    BuyProductPromo,
    CampaignPromo,
    ContentPromo,
    ContentTiles,
    DrinkAwareBanner,
    EventList,
    FacebookFeed,
    FollowUs,
    ImageHero,
    Ingredients,
    MarketSwitcher,
    Meta,
    Method,
    Navigation,
    ProductHero,
    ProductList,
    ProductStory,
    PurchaseIntegration,
    RecipeAndFilterList,
    RecipeCard,
    RecipeHero,
    SocialSharing,
    StructuredData,
    TextBlock,
    ThreeProductPromo,
    TwoColContentCard,
    TwoProductPromo,
    Video,
    VideoHero,
    WhereToBuy,
};
