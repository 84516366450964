/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'

import * as utils from 'global/utils/utils'

import AppError from "../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import HeadingWithCopy from "../heading-with-copy/";
import Footer from "../footer";
import buildPage from "../../page-builder";

import NavCustom from "../nav-custom";

import btnRecipe from 'global/images/ftc2024/btn__recipe.png'

import cocktailRays from 'global/images/ftc2024/cocktail_rays.png'
import cocktailCptCola from 'global/images/ftc2024/cocktail-captain-cola.png'
import cocktailCptDaq from 'global/images/ftc2024/cocktail-captain-daiquiri.png'
import cocktailHurricane from 'global/images/ftc2024/cocktail-hurricane.png'

import rumStreetBanner from 'global/images/ftc2024/rum_street_logo.png'
import rumStreetMap from 'global/images/ftc2024/rum_street_map.png'
import redeemGold from 'global/images/ftc2024/btn__redeem--gold.png'
import rsvpGold from 'global/images/ftc2024/btn__signup--gold.png'
import sideBtn from 'global/images/ftc2024/side_prizebtn.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "eyebrow": "WELCOME TO",
      "image": rumStreetBanner,
      "imageMobile": rumStreetBanner,
      "imagealt": "Captain Morgan Rum Street",
      "title": "",      
      "srText": "Captain Morgan Rum Street",
      "copy": "Bourbon Street's iconic cocktails share one key ingredient: RUM!<br />So, Captain Morgan is transforming it into Rum Street for <span class='inline-block'>Super Bowl LIX</span> weekend.",
      "imageSize":"foty",
      "pModalOpen": false,
      "pIsRumStreet":false,
    },
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler (isRumStreet = false) {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true,
      pIsRumStreet: isRumStreet
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }

  initGallery() {
    const gallerySlider = document.getElementsByClassName('gallery-slider')

    if (gallerySlider.length > 0) {
      var slider = new Swiper ('.gallery-slider', {
        slidesPerView: 1,
        // centeredSlides: true,
        loop: true,
        loopedSlides: 6,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
      });
    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  waitForElem () {
    const gallerySlider = document.getElementsByClassName('gallery-slider')
    if(gallerySlider !== "undefined"){
      setTimeout( () => {
        this.initGallery()
      }, 500)
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }
  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text') || ''
        var btnHref = $(this).data('url') || ''
        var btnPath = $(this).data('path') || ''
        var btnName = $(this).data('name') || ''
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnPath,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })

      // Set the date we're counting down to
      // const countDownDate = new Date('2024-09-30T09:00:00.000-04:00').getTime()
      // // Update the count down every 1 second
      // let x = setInterval(function () {
      //   // Get today's date and time
      //   const now = new Date().getTime()

      //   // Find the distance between now and the count down date
      //   const distance = countDownDate - now

      //   // Time calculations for days, hours, minutes and seconds
      //   let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      //   let hours = Math.floor(
      //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      //   )
      //   let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      //   let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      //   // Display the result in the element with id="demo"
      //   //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      //   //   + minutes + "m " + seconds + "s ";
      //   const daysDiv = document.getElementById('days')
      //   const hoursDiv = document.getElementById('hours')
      //   const minutesDiv = document.getElementById('minutes')
      //   const secDiv = document.getElementById('seconds')

      //   if(daysDiv && hoursDiv && minutesDiv && secDiv) {
      //     daysDiv.innerHTML = days
      //     hoursDiv.innerHTML = hours
      //     minutesDiv.innerHTML = minutes
      //     secDiv.innerHTML = seconds
      //     // If the count down is finished, write some text
      //     if (distance < 0) {
      //       clearInterval(x)
      //       document.getElementById('days').innerHTML =
      //           '<span>' + '0' + '</span> <br/> days '
      //       document.getElementById('hours').innerHTML =
      //           '<span>' + '0' + '</span> <br/> hours '
      //       document.getElementById('minutes').innerHTML =
      //           '<span>' + '0' + '</span> <br/> minutes '
      //       document.getElementById('seconds').innerHTML =
      //             '<span>' + '0' + '</span> <br/> seconds '
      //     }
      //   }
      // }, 1000)
    })
    setTimeout( () => { this.waitForElem(), 1500})
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "Captain Morgan Rum Street | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          inactive: true,
          url: '/followthecaptain/about',
        },
        {
          title: 'Rum Street',
          url: '/followthecaptain/rum-street',
        },
        {
          title: 'NFL Fans of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
          inactive: true,
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          path: '/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    const galleryData = [
      {
        image: cocktailHurricane,
        imagealt: "A glass of a Hurricane coktail made with Captain Morgan",
        name: "Hurricane",
        url: "/en-us/cocktails/hurricane/"
      },
      {
        image: cocktailCptDaq,
        imagealt: "A glass of Captain Daiquiri",
        name: "Captain Daiquiri",
        url: "/en-us/cocktails/captain-daiquiri/"
      },
      {
        image: cocktailCptCola,
        imagealt: "A glass of Captain and Cola",
        name: "Captain and Cola",
        url: "/en-us/cocktails/captain-and-cola/"
      },
    ]

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
          entrySource={this.state.pIsRumStreet === true ? "US_CPT_SuperBowl_FY25" : null}
          eventKey={this.state.pIsRumStreet === true ? "APIEvent-2e577fa3-1798-b219-70d4-55b32ee236fb" : null}
          subhead={this.state.pIsRumStreet === true ? "Join our crew to be notified about Super Bowl LIX's most unforgettable party!" : null}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="Legends start when you Follow The Captain. Follow us for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          
          <title>{pageTitle}</title>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--foty ftc24--rumst" id="main" role="main">
          {/* DRIZLY SECTION */}
          <section className="flex heading__section foty__heading section--heading rstwocol">
            <div className="container container--1600">
              <Heading {...this.state.headingProps} />
            </div>
            <div className="container container--1200">
              <div className="rstwocol__wrap rstwocol__centered flex text--white">
                <div className="rstwocol__block rstwocol__block--left">
                 <div className="rstwocol__content">
                  <div className="rstwocol__title">
                    Join our crew for epic adventure, starting with Captain Morgan dropping anchor at Super Bowl LIX!
                  </div>
                  <button
                    aria-label="Sign Up"
                    className="btn clear btn--ga"
                    data-text="Sign Up"
                    data-name="Sign Up"
                    data-path=""
                    data-section="Rum Street CTA"
                    data-url=""
                    onClick={() => this.pModalClickHandler(true)}
                    rel="noreferrer"
                    title="Sign Up"
                  >
                    <img
                      aria-label="Sign Up"
                      alt="Sign Up"
                      className="cta__image"
                      src={rsvpGold}
                      />
                  </button>
                 </div>
                </div>
                <div className="rstwocol__block rstwocol__block--right">
                  <div className="rstwocol__content">
                  <div className="rstwocol__title">
                    Unlock $10 in <span className="inline-block">'Captain's Cash'</span> and support your favorite bar or restaurant on Bourbon St!
                  </div>
                  <a
                    aria-label="Get a captain cocktail on us! Redeem Now!"
                    className="clear btn--ga"
                    data-text="Redeem Now"
                    data-name="Redeem Now"
                    data-path="/followthecaptain/rum-street/captaincash"
                    data-section="Rum Street CTA"
                    data-url="https://www.captainmorgan.com/followthecaptain/rum-street/captaincash"
                    href="/followthecaptain/rum-street/captaincash"
                    rel="noreferrer"
                    title="Get a captain cocktail on us! Redeem Now!"
                  >
                    <img
                      aria-label="Get a captain cocktail on us! Redeem Now!"
                      alt="Redeem Now"
                      className="cta__image"
                      src={redeemGold}
                      />
                  </a>
                 </div>
                </div>
                {/* <div className="rstwocol__block rstwocol__block--full">
                  <p className="disclaimer">
                    ‡Spending at a local bar/restaurant is SUGGESTED and not required. ‘Captain’s Cash’ is only awarded in the form of a mobile payment.
                  </p>
                </div> */}
              </div>
            </div>
          </section>
          <section className="flex content rsmap">
            <div className="container container--full">
              <div className="content__wrap flex">
                <div className="rsmap__heading">
                  <h2 className="text--white rumst__heading">
                    Get your Captain Morgan cocktail here during <span className="inline-block">Super Bowl LIX</span> weekend:
                  </h2>
                </div>
                <img src={rumStreetMap} alt="Head to these Bourbon Street bars to get your cocktail made with Captain Morgan." />
              </div>
            </div>
          </section>
          <section className="flex section--gallery">
            <div className="container container--1200">
              <div className="gallery__heading">
                <h2 className="text--red rumst__heading">
                  Can't join us on <span className="inline-block">Rum Street?</span>
                </h2>
                <div className="gallery__copy text--copy">
                  Join the fun making these signature cocktails at home!
                </div>
              </div>
              <div className="slideswrap">
                <div className="slideswrap__wrap">
                  <div className="gallery">
                    <div className="swiper-container gallery-slider">
                      <div className="swiper-wrapper">
                        {galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
                          return (
                            <div key={`gallery_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                              <div className="content-wrap">
                                <div className="content__block content__block--left">
                                  <img src={cocktailRays} aria-hidden="true" className="img-bg" />
                                  <img src={item.image} alt={item.imagealt} className="img-cocktail" />
                                </div>
                                <div className="content__block content__block--right">
                                  <div className="content__block--copy">
                                    <h3 className="text--white">{item.name}</h3>
                                    <a
                                      aria-label={`View ${item.name} Recipe`}
                                      className="btn__mapfilter btn__mapfilter--chest btn--ga clear"
                                      data-text={`View ${item.name} Recipe`}
                                      data-name={`View ${item.name} Recipe`}
                                      data-path={item.url}
                                      data-section="gallery"
                                      data-url={`https://www.captainmorgan.com/${item.url}`}
                                      href={item.url}
                                      rel="noreferrer"
                                      title={`View ${item.name} Recipe`}
                                    >
                                      <img src={btnRecipe} aria-label={`View ${item.name} Recipe`} width="200px" height="200px" loading="lazy" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="filter" className="filter flex align-center justify-center fixed z-10">
              <div className="filter__chest">
                <a
                  aria-label="Party Sign Up"
                  className="btn__mapfilter btn__mapfilter--chest btn--ga"
                  data-text="Party Sign Up"
                  data-name="Party Sign Up"
                  data-path="/followthecaptain?showPrizeGame=true"
                  data-section="sticky aside"
                  data-url="https://www.captainmorgan.com/followthecaptain?showPrizeGame=true"
                  href="/followthecaptain?showPrizeGame=true"
                  rel="noreferrer"
                  title="Party Sign Up"
                >
                  <img src={sideBtn} aria-label="A golden treasure chest" width="200px" height="200px" loading="lazy" />
                </a>
              </div>
            </div>
          </section>           
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew for an epic adventure, starting with this: Captain Morgan is dropping anchor at Super Bowl LIX!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
