import "../../organisms/RsvpForm/style.scss";
import React, { useEffect,useState } from "react";
import fetch from "isomorphic-fetch";
import moment from "moment";
import $ from 'jquery'
import {load} from "recaptcha-v3";

import cptShip from 'global/images/ftc2024/crm_ship.png'

const cn = require('classnames')

const Newsletter = (props) => {
  const modalType = props && props.isOpen ? "open" : ''
  const thanksST = props && props.thanksstatus ? props.thanksstatus : false
  const [date, setDate] = useState("");
  const [thanks, setThanks] = useState(thanksST)
  const entrySource = props && props.entrySource ? props.entrySource : ''
  const eventKey = props && props.eventKey ? props.eventKey : ''

  function checkAge (dob) {
    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);

    if (moment().diff(birthday, "years", false) < 21 || moment().diff(birthday, "years", false) > 110) {
      return true
    } else {
      return false
    }
  }
 
  // eslint-disable-next-line complexity
  async function formSubmit(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#prmodalSubmit').prop('disabled', true).addClass('disabled')
    $('#prError').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });

    event.preventDefault()
    const fname = $("#prCP_FirstName").val();
    const lname = $("#prCP_LastName").val();
    const email = $("#prCP_EmailId").val();
    const dob =  moment($("#prCP_DOB").val()).format("YYYY-MM-DD")
    const zip = $("#prPR_4_324_1_freetext").val();
    const tc = $("#tc").prop("checked");
    let captcha = "";

    //check required fields are present
    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        || zip.trim() === "" 
        || date.trim() === ""
        || tc === false) {

      if (fname.trim() === "") {
        $("#prCP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#prCP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#prCP_EmailId").addClass("error");
      }

      if (zip.trim() === "") {
        $("#prPR_4_324_1_freetext").addClass("error");
      }

      if (date.trim() === "") {
        $("#prCP_DOB").addClass("error");
      }

      if (tc==false) {
        $("#tc").addClass("error");
      }
      formError = true
    }
    //check age
    ageError = checkAge(dob)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('prmodalForm'));
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "prHD_PR_1_64_1_Brand") {
          // eslint-disable-next-line radix
          send['HD_PR_1_64_1_Brand'] = parseInt(value);
        }else if(key === "prPR_4_92_1"){
          send['PR_4_92_1'] = parseInt(value);
        } else if (key === "prPR_4_92_1" || key === "tc") {
          if (value === "on") {
            send[key] = "1";
          }else{
            send[key] = '99'
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "prCP_DOB") {
          send['CP_DOB'] = moment($("#prCP_DOB").val()).format("YYYY-MM-DD")
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          if(key.startsWith('pr', 0)) {
            send[key.slice(2)] = value;
          }else {
            send[key] = value;
          }
        }
        const ppVer = window.dg_footer_configurations.hg_PP_Version;

        if (ppVer) {

            send['ppVersion'] = ppVer;

        } else {

            throw new Error("Cannot get Privacy Policy version");

        }
        send['PR_1_64_1'] = true
        send['PR_1_64_2'] = true
        send['newsletter_optin_terms'] = true
        send['HD_PromoCode'] = 'DNA107CPT0322WA0013'
        if (entrySource !== '') {
          send['entrySource'] = entrySource
        }
        if (eventKey !== '') {
          send['eventKey'] = eventKey
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const res = await fetch(`/api/newsletter`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          // console.log('RES >>>> ', res)
          if(res === 200) {
            setThanks(true)
            props.thanksCallBack()
          }else{
            // ERROR GOES HERE
            $('#prError').html('<div>Something went wrong. Please try again.</div>')
            $('#prmodalSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          if($('#prmodalSubmit')) {
            $('#prmodalSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        let errorMsg = "";
        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || zip.trim() === ""
          || date.trim() === "" 
          || tc === false
        ) {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 21 and over to enter.</div>";
        }

        // if (tc === false) {
        //   errorMsg += "<div>A required field is missing. </div>";
        // }

        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#prmodalSubmit').prop('disabled', false).removeClass('disabled')
        $('#prError').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  const handleDateChange = (e) => {
    let value = e.target.value;
    let backsapce = date.length > value.length;

    if (!backsapce && (date.length == 1 || date.length == 4)) {
      value += "/";
    }
    setDate(value);
  };

  

  useEffect(() => {
  
    $(function () {
      $('#CP_PhoneNumber').on('keydown keyup', function(e) {
        if(isNaN(e.key)){
          if(e.key.toString().toLowerCase() !== 'backspace' && e.key.toString().toLowerCase() !== 'tab') {
            e.preventDefault()
          }
        }else{
        }
      })
    })
    $("#prCP_DOB").on("keydown keyup", function (e) {
        if (isNaN(e.key)) {
          if (
            (e.key).toLowerCase() !== "backspace" &&
            (e.key).toLowerCase() !== "tab"
          ) {
            e.preventDefault();
          }
        } else {
        }
      });
    $("body").on("click", "#age_confirm_btn", function () {

      const month = document.getElementById(
        "age_select_month_of_birth"
      ) 
      const day = document.getElementById(
        "age_select_day_of_birth"
      ) 
      const year = document.getElementById(
        "age_select_year_of_birth"
      ) 

      const birthday = month.value + "/" + day.value + "/" + year.value;
        setDate(birthday)
    });


  })

  return (
    <div className={cn('prmodal', thanks === true ? 'prmodal--open prmodal--thanks' : '' )} id='prModal'>
      <button aria-label="close" className="close" onClick={(e)=>props.modalClose(e)}>
      </button>
      <div className="prmodal__wrap prmodalForm">
        {thanks === false &&
          <div className="correct__form modalform formWrapper">
            <div className="formTitle">
              Start your adventure
            </div>
            <div className="formCopy">
              {props.subhead}
            </div>
            <form id="prmodalForm" runat="server" action="/api/CIDBapi_Form">
              <div className="inputWrapper half half--left">
                <label htmlFor="prCP_FirstName">First Name*</label>
                <input id="prCP_FirstName" name="prCP_FirstName" type="text" placeholder="First Name*" className="claimInput" required />
              </div>

              <div className="inputWrapper half half--right">
                <label htmlFor="prCP_LastName">Last Name*</label>
                <input id="prCP_LastName" name="prCP_LastName" type="text" placeholder="Last Name*" className="claimInput" required />
              </div>

              <div className="inputWrapper full">
                <label htmlFor="prCP_EmailId">Email*</label>
                <input id="prCP_EmailId" name="prCP_EmailId" type="email" placeholder="Email*" className="claimInput" required />
              </div>

              <div className="inputWrapper half half--left">
                <label htmlFor="prPR_4_324_1_freetext">Zip Code*</label>
                <input id="prPR_4_324_1_freetext" name="prPR_4_324_1_freetext" placeholder="Zip Code*" type="text" maxLength={10} className="claimInput" required />
              </div>

              <div className="inputWrapper half half--right">
                <label htmlFor="prCP_DOB">Date of Birth*</label>
                <input id="prCP_DOB" name="prCP_DOB" type="text" className="claimInput" required  value={date}
                      maxLength={10}
                      onChange={handleDateChange}
                      placeholder="mm/dd/yyyy"/>
              </div>
              <div className="inputWrapper full checkbox">
                <input id="tc" name="tc" type="checkbox" className="claimInput cliamInput--checkbox" required />
                <label htmlFor="tc" dangerouslySetInnerHTML={{
                  __html: `Tick here if you would like us to use your email to keep you informed about products, services and events from Captain Morgan & other Diageo brands including TheBar.com. You can unsubscribe at any time.*<br /><br />By signing up you accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank' style='text-decoration:underline;'">Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' style='text-decoration:underline;' target='_blank'>Privacy and Cookie Notice</a>.`
                  }}
                />
              </div>

              <div className="inputWrapper full">
                  <div className="errorMessage" id="prError"></div>
              </div>

              <div className="inputWrapper full submit">
                <input id="source" name="source" value="followthecaptain" type="hidden" />
                <input id="PR_4_466_1_freetext" name="prPR_4_466_1_freetext" value="" type="hidden" />
                <input className="submit btn btn--bggold btn--textwhite" type="submit" value="Join the crew" id='prmodalSubmit' onClick={(event) => formSubmit(event)} />
              </div>
            </form>
          </div>
        }
        {thanks === true &&
          <div className="correct__form modalform formWrapper prmodalThanks">
            <div className="formTitle">
              Thanks for joining the crew!
            </div>
            <div className="formCopy">
              Start your adventure and Follow the Captain.
              <div className="image__wrap">
                <img src={cptShip} aria-hidden="true" />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Newsletter;
